import React from "react";
import styled from "styled-components";

const Div = styled.div`
  display: block;
  font-size: 1em;
  margin-bottom: 0;
  margin-left: 5pt;
  margin-right: 5pt;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  .calibre1 {
    display: block;
  }
`;

const Terms = () => {
  return (
    <Div>
      델리퀵 서비스 이용 약관 - 사업자용
      <br class="calibre1" />
      <br class="calibre1" />
      발효일: 2019년 10월 05일
      <br class="calibre1" />
      <br class="calibre1" />
      제 1 조 (목적)
      <br class="calibre1" />
      <br class="calibre1" />
      본 델리퀵 서비스 이용 약관(이하 “본 약관”)은 퍼블릭스 주식회사(계열회사,
      임직원 및 계약관계에 따른 이해당사자를 포함하여, 이하 총칭하여 “회사”)가
      제공하는 델리퀵 사업자의 사이트의 이용과 관련하여 회사와 판매자의 권리,
      의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 2 조 (정의)
      <br class="calibre1" />
      <br class="calibre1" />
      본 약관에서 사용하는 용어의 의미는 다음과 같습니다.?본 약관에서 정의되지
      않은 용어는 일반 거래 관행에 따라 정의된 의미를 가집니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. “델리퀵”란 판매자가 회사의 델리퀵 서비스를 이용할 수 있도록 정보통신
      시설/장비/네트워크를 통해 회사가 제공하는 서비스 플랫폼을 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      2. “델리퀵 서비스”(또는 “서비스”)란 판매자가 매장에 대한 정보를 노출하고,
      델리퀵을 이용해서 고객으로부터 주문을 받고
      온라인페이(알리페이,페이팔,유니온페이,기타 델리퀵에서 제공하는 모든 전자적
      결제수단)를 통해 결제할 수 있도록 회사가 제공하는 인터넷 기반의 중개
      서비스를 의미하며, 회사에 의해 수시로 변경되는 델리퀵 서비스를 포함합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      3. “상품”이란 판매자가 고객에게 판매하는 음식 등을 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      4. “판매자”란 회사의 델리퀵에 접속하여 본 약관에 따라 회사와 이용계약을
      체결하고, 회사가 제공하는 델리퀵 서비스를 통하여 고객에게 상품을 판매하는
      자를 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      5. “파트너”란 판매자가 델리퀵을 통해 고객에게 판매한 상품을 고객에게
      전달하는 업무를 수행하는 자를 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      6. “고객”이란 델리퀵을 이용하여 상품을 열람하거나 판매자로부터 상품을
      구매하는 소비자를 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      7. “이용정책”이란 델리퀵, 판매자 전용 시스템 등에 게시된 서비스에 대한
      이용정책, 이용 약관, 규칙 및 지침 및 서비스 이용과 관련하여 회사가 설정한
      기타 정책(향후 회사가 개정하는 내용 포함)을 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      8. “판매자 전용 시스템”이란 회사가 델리퀵 서비스 제공을 위해 필요한 정보를
      판매자와 상호 교환하기 위해 판매자에게 제공하는 툴을 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      9. “게시물”이란 델리퀵 및 델리퀵판매자 전용 시스템 등에 등록한 문자, 음성,
      음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크
      등(형태, 형식 불문)을 의미합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      10. “할인쿠폰 등”이란 거래시 해당 거래대금에서 표시된 금액 또는 비율
      상당의 금액만큼을 할인 받을 수 있는 회사가 발행한 쿠폰 기타 회사가
      프로모션 행사 등을 통해 제공하는 혜택을 의미합니다. 구체적인
      내용(사용대상?방법?기한 및 제한 사항 등)은 서비스 관련 웹페이지 등에
      개별적으로 표시되고, 할인쿠폰 등의 종류 및 내용은 회사정책에 따라 달라질
      수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 3 조 (본 약관의 게시, 유효성 및 개정)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 판매자가 쉽게 알 수 있도록 본 약관을 델리퀵 또는 판매자 전용
      시스템에 게시하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 판매자의 델리퀵 서비스 이용과 관련하여 별도의 이용정책 등을
      운영할 수 있으며, 해당 운영정책 등은 본 이용약관의 일부를 구성합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에
      관한 법률(이하 “정보통신망법”)”, “전자금융거래법” 등 관계 법령을 위배하지
      않는 범위에서 본 약관을 개정할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사가 본 약관(이용정책을 포함하며, 이하 본 조에서 동일함)을 개정하는
      경우, 모든 개정 조항은 본 약관의 현재 버전 및 개정안의 발효일, 개정이유와
      함께 발효일 7일 전부터(단, 수정 내용이 판매자에게 불리한 경우에는 14일)
      발효일 직전일까지 델리퀵, 판매자 전용 시스템 등에 게시되거나 이메일 또는
      기타 수단을 통하여 통지되어야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 회사가 전항에 따라 본 약관의 개정안을 공개하거나 통지하면서 판매자에게
      7일(판매자에게 불리한 개정안의 경우에는 14일) 이내에 의사표시를 하지
      않으면 의사표시를 한 것으로 본다는 뜻을 명확하게 알리거나 통지하였음에도
      불구하고, 판매자가 거부 의사를 명시적으로 의사표시 하지 않는 경우 판매자는
      본 약관의 개정안에 동의한 것으로 봅니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 판매자가 개정 약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을
      적용할 수 없으며, 이 경우 판매자는 이용계약을 해지할 수 있습니다. 단,
      회사는 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우 본 약관을 해지할
      수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 4 조 (판매자에 대한 통지)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 이메일이나 기타 서면 등의 방법으로 판매자에게 통지할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사가 불특정 다수 판매자 또는 모든 판매자들에게 통지해야 하는 경우에는
      1주일 이상 델리퀵 또는 판매자 전용 시스템 등에 해당 통지내용을
      게시함으로써 개별 통지에 갈음할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 5 조 (판매자 등록)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 델리퀵 서비스를 이용하기 위해 판매자 등록을 신청하고자 하는 자(이하
      “등록신청자”)는 본 약관에 동의하고, 회사가 제공하는 등록 신청서 양식에
      필요한 정보 등을 등록해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 등록신청자는 신원정보, 매장정보와 상품 정보 등을 회사에 제공해야 하고,
      회사는 델리퀵 서비스를 제공하기 위하여 관련 법령에 따라 판매자로부터
      제공받은 위 정보를 수집할 수 있습니다. 이 경우, 회사는 등록신청자에게
      추가적인 정보의 제공을 요청할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 등록신청자에게 신원 확인 절차를 요구할 수 있으며, 그러한 절차가
      불가능할 경우에는 회사는 신원 확인을 위한 관련 자료를 제출하도록 등록
      신청자에게 요구할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 등록신청자는 사업자등록증 등 회사가 요청하는 필수서류를 제출하여야 하고,
      필수서류를 제출하지 않거나 제공한 서류가 부정확 또는 허위의 사실인 경우
      회사는 가입을 승인을 거절하거나 보류할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 만 19세 이상의 사업자(자연인 또는 법인 불문)는 상기 제1항의 등록 신청
      자격이 있습니다. 등록 신청자는 실명으로 등록 신청을 해야 합니다(법인인
      경우, 법인의 이름으로 하여야 하며, 이하 본 조에서 동일하게 적용됩니다).
      실명이 아닌 이름을 사용하거나 다른 사람의 정보를 도용하는 등록신청자는
      서비스 이용이 제한되거나 관련 법률 및 규정에 따라 처벌받을 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 본 약관은 회사가 전항에 따른 등록 신청을 수락하면 효력이 발생됩니다. 이
      경우, 회사는 제4조에 의한 방법으로 명시된 수락 의사를 통보하거나 공개할
      것이며, 본 약관은 수락 의사가 등록신청자에게 전달되거나 공개된 시점에 양
      당사자에 대해 유효하게 효력이 발생됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있어
      정상적인 서비스의 제공이 어렵다고 판단되는 경우에는 승낙을 유보할 수
      있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑧ 회사는 다음 각 호의 경우에는 등록 신청을 거부할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. 등록신청자가 회사와의 거래관계에서 계약 조건 및 관련 법률, 규정 등을
      위반한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      2. 등록신청자가 제공한 정보에 허위 정보, 정보 누락, 오타 등이 포함되어
      있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      3. 등록신청자가 본인의 매장을 영업하기 위한 목적이 아니거나 타인 명의로
      가입을 신청하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      4. 등록신청자가 미성년자인 경우
      <br class="calibre1" />
      <br class="calibre1" />
      5. 등록신청자가 실명으로 등록 신청을 하지 않는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      6. 성명, 주민등록번호, 사업자등록번호, 법인등록번호 및 기타 주요 등록
      정보가 이미 등록된 다른 판매자와 동일한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      7. 본 약관의 해지/취소로부터 2개월 이내에 재등록 신청서가 제출된 경우
      <br class="calibre1" />
      <br class="calibre1" />
      8. 약관 및 운영정책 위반으로 해지된 이력이 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      9. 약관 및 운영정책 위반으로 이용 정지된 상태에서 탈퇴한 이력이 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      10. 약관 및 운영정책을 중대하게 위반한 후 탈퇴한 이력이 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      11. 가입 신청자가 입력한 정보에 허위 또는 오류가 있거나 회사가 요청하는
      증빙서류 등을 제출하지 아니하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      12. 기타 이에 준하는 사유로서 회사가 가입승인을 하지 않는 것이 적절하다고
      판단하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      ⑨ 판매자는 판매자(판매자를 대신하는 제3자 등을 포함)가 회사에 부정확한
      정보 또는 관련 자료를 제공함으로써 회사가 입은 손해에 대해 책임을 져야
      하며, 그러한 손해에 대해 회사에게 배상하고 회사를 면책 시켜야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 6 조 (판매자 등록정보 변경 및 유효성 확인 관련)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 상호, 대표자, 소재지 연락처, 업종 등 등록정보에 변경 사항(가맹본부와의
      가맹점계약 해지, 폐업 등을 포함)이 있는 경우, 판매자는 즉시 등록정보를
      회사가 정한 방식으로 변경하거나 해당 변경 사실을 회사에 통지하여야 합니다.
      다만, 서비스 관리를 위해 필요한 실명, 아이디, 사업자등록번호 등은 수정할
      수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사에 정보를 제공하거나 등록정보를 변경할 때, 허위 정보를
      제공해서는 아니 되며, 관련 자료를 즉시 제공하여야 합니다
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 변경을 신청한 정보가 법령, 정책, 약관, 검수기준, 이용안내 등에 부합하지
      않는 경우 회사는 해당 정보의 변경을 제한할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 사업자등록번호를 기재하여 가입한 판매자는 휴업 또는 폐업 상태가 되었을
      경우 회사가 정하는 절차에 따라 회사에 정보 수정을 요청하여야 합니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 회사의 고의
      또는 중대한 과실이 없는 이상 책임지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 7 조 (관련자료의 제출 등)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 제5조에 따라 판매자가 제공 또는 등록한 정보의 정확성을 확인하기 위하여,
      회사는 관련 법령 및 규정이 허용하는 한도 내에서 판매자에게 관련 자료의
      제공을 요구할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자가 정당한 사유 없이 정보 및 관련 자료를 제공하지 않는 경우, 회사는
      본 약관의 해지/취소, 서비스 사용 제한 또는 결제 금액의 지급 보류를
      포함하여 필요한 조치를 취할 수 있습니다. 이 경우, 판매자는 모든 관련 위험
      및 책임을 부담하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 8 조 ( 이용제한 등)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 판매자가 본 약관 및 법령상의 의무를 위반하거나 델리퀵 서비스의
      정상적인 운영을 방해한 경우 경고, 일시정지, 영구이용정지 등의 방법으로
      서비스 이용을 제한할 수 있습니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 주민등록법을 위반한 명의도용 및 결제도용 등과 같이 관련법을
      위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른
      영구이용정지 시 서비스 이용을 통해 획득한 기타 혜택 등도 모두 소멸되며,
      회사는 회사의 고의 또는 중대한 과실이 없는 한 이에 대해 별도로 보상하지
      않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 다음 각 호가 발생하는 경우 판매자정보의 보호 및 운영의 효율성을
      위해 일시적으로 이용을 제한할 수 있습니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      1. 회사와 제휴된 금융기관 등의 전산 마감 시간 등에 따라 서비스 오류 발생
      가능성이 높은 시간의 경우
      <br class="calibre1" />
      <br class="calibre1" />
      2. 컴퓨터 등 정보통신 설비의 보수, 업그레이드, 점검, 교체, 해킹 등으로
      정상적인 서비스 제공이 어려운 경우
      <br class="calibre1" />
      <br class="calibre1" />
      3. 천재지변, 전쟁, 폭동, 테러 등 불가항력적 사유로 서비스 운영이 불가능한
      경우
      <br class="calibre1" />
      <br class="calibre1" />
      4. 판매자가 계속해서 1년 이상 로그인하지 않거나 1년 이상 서비스 거래가
      없는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 판매자가 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우
      주의, 경고, 광고중단, 계약 해지 등 필요한 조치를 할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. 판매자가 델리퀵 서비스를 이용하는 고객에게 회사가 제공하는
      온라인페이먼트를 통하지 아니하고 직접 상품을 판매하거나 판매하려 시도한
      경우
      <br class="calibre1" />
      <br class="calibre1" />
      2. 회사의 직원(델리퀵 서비스 상담 직원, 담당자 등 회사와 관련된 업무를
      수행하는 수행자를 모두 포함)에게 욕설, 모욕, 폭언, 성희롱, 성추행, 위협,
      폭력 등의 행위를 한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      3. 판매자가 회사로부터 전달받은 고객의 정보를 본 약관 상의 의무 이행을
      위한 목적 외 다른 목적으로 사용한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      4. 판매자의 상품이나 고객서비스의 품질에 대한 고객의 평가(리뷰 작성, 별점
      평가, 상담 민원 등의 방법을 모두 포함)가 현저히 낮다고 회사가 판단하는
      경우
      <br class="calibre1" />
      <br class="calibre1" />
      5. 판매자가 델리퀵 서비스와 관련하여 얻게 된 정보를 판매자의 영업을 위한
      마케팅에 활용하는 등 무단으로 사용하는 일체의 행위를 한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      6. 판매자 신청 서류의 기재사항을 허위로 작성한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      7. 판매자의 고의 또는 과실로 회사 또는 고객에게 상당한 손해가 발생한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      8. 거래한 고객으로부터 민원이 빈발하여 판매자로 부적당하다고 인정되는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      9. 판매자가 본 약관 및 이용정책 기타 회사의 가이드라인을 위반한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      10. 판매자가 회사의 델리퀵 서비스를 부정한 목적으로 사용한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      11. 회사 및 기타 제 3 자의 저작권 등 지적재산권을 침해하는 경우?
      <br class="calibre1" />
      <br class="calibre1" />
      12. 회사, 고객 및 기타 제 3 자의 명예를 손상시키거나 업무를 방해하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      13. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
      에 공개 또는 게시하는 경우?
      <br class="calibre1" />
      <br class="calibre1" />
      15. 법령 위반, 공서양속 위반 등 기타 객관적으로 회사가 이용제한 등을 할
      필요가 있다고 인정되는 경우 등
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및
      운영정책에서 정하는 바에 의합니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는
      판매자에 대한 통지 절차에 따라 통지합니다. 다만, 계약 해지의 경우를
      제외하고 회사가 긴급하게 이용제한 등이 필요하다고 인정한 경우에는 사후
      통지할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 회사는 회사의 고의 또는 중대한 과실에 의하지 않는 이용제한 등에 대해서는
      어떠한 책임도 지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 9 조 (본 약관의 해지)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 제8조 제4항 각 호의 어느 하나에 해당하는 사유가 발생한 경우 제4조
      제1항에 의한 방법으로 판매자에게 통지함으로써 본 약관을 해지하거나
      쿠팡이츠 서비스를 중단할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 언제든지 회사가 정한 방식으로 통지하여 본 약관을 해지할 수
      있습니다. 이 경우, 해지 의사를 통지하기 전에, 판매자는 서비스 이용을
      중단해야 하며, 판매자가 서비스를 이용하여 거래를 이행한 경우, 판매자는
      해당 거래를 완료하고 해당 고객과 회사에게 지급할 금액을 지급해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자는 전항에 따라 해지를 하는 경우, 고객을 보호하기 위하여 해지일
      현재 아직 처리되지 않은 주문에 관한 배송, 교환, 환불, 수리 등 필요한
      조치를 취하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 본조 제2항과 제3항에 따라 판매자가 본 약관의 해지 의사를 회사에
      통지하였으나, 본 약관에 따른 해지를 위해 판매자가 이행하여야 할 의무가
      완료되지 않은 경우에는 그 의무를 이행하는 것을 조건으로 본 약관이 해지되는
      것으로 간주됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 본 약관이 해지되는 경우, 회사는 판매자에게 부여된 모든 혜택을 철회할 수
      있으며, 해지 사유 및 해당 사유의 해소 여부를 고려하여 판매자의 재등록
      신청을 거부할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 본 약관이 해지되는 경우, 회사는 동일한 계정 정보(사업자등록번호, 아이디
      등)의 재가입을 제한할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 회사는 안정적인 서비스 운영 및 검색 고객의 보호를 위해 판매자의 탈퇴
      또는 직권 해지일로부터 6개월간 재가입을 제한할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑧ 판매자가 약관을 해지할 경우, 관계 법령 및 개인정보처리방침에 따라
      보유하는 정보를 제외하고는, 판매자의 게시물, 서비스 이용 정보 등 모든
      데이터가 삭제됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑨ 판매자가 판매한 상품으로 인해 발생하는 판매자의 의무 및 책임에 관한 본
      약관 상의 조항은 본 조항에 따라 본 약관이 해지된 이후에도 유효하게
      존속됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 10 조 (수수료)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 델리퀵 서비스 및 온라인전자결제를 유형별로 정의하고, 각 유형의
      서비스에 대한 수수료(이하 “서비스 수수료”)를 결정할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 서비스이용에 대한 수수료를 부과하지 않습니다. 단, 향후 회사정책에
      따라 달라질 수 있으며 영세가맹점, 중소가맹점, 일반가맹점에 따라 수수료율을
      차등하여 적용할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 이용 정책상 향후 발생할 수 있는 서비스 수수료의 종류, 요율, 청구
      방법 및 지급 방법, 세금 계산서 발행 등에 관한 특정 조건을 명시하고, 판매자
      전용 시스템, 델리퀵 등을 통해 동일 내용을 통지하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 필요에 따라 서비스 수수료를 신규로 설정 또는 변경할 수 있으며,
      서비스 유형별로 서비스 수수료의 계산 및 지급 방법 등을 달리 정할 수
      있습니다. 이 경우 회사는 상기 제2항의 방식에 따라 신규 설립된 서비스
      수수료 및 변경된 서비스 수수료의 세부 사항을 통지하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 고객이 할인쿠폰 등을 사용하는 경우, 해당 판매자는 할인쿠폰 등에 명시된
      대로 고객의 거래가격에 대한 할인을 제공합니다. 이러한 경우, 서비스
      수수료는 할인쿠폰 등이 적용되기 전의 거래가격에 적용되는 서비스 수수료에서
      할인 금액에 대한 회사의 기여분에 상응하는 금액을 직접 공제하여 계산한
      금액으로 합니다. 단, 전술한 내용은 사전에 회사와 상의 없이 판매자가
      거래가격에 대해 제공한 할인에 대해서는 적용되지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 회사는 상기 5항에 명시된 할인쿠폰 등의 세부사항을 이용정책에 따라
      정하고, 판매자 전용 시스템 및 서비스 관련 웹페이지 등을 통해 이를
      통지하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 11 조 ( 대금 정산 및 유예)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 판매자 별 서비스 유형 별로 결제일, 결제방법, 구매 안전 서비스
      운영(결제 금액의 에스크로) 등의 조건을 이용 정책에 명시하며, 판매자 전용
      시스템, 델리퀵 등을 통해 해당 내용을 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 계산된 결제 금액에서 서비스 수수료 및 기타 판매자가 회사에
      지불해야 하는 상계 가능 금액을 차감하여 잔액을 판매자에게 지급하고, 관련
      세부 내역(예를 들면, 상품 판매 대금, 공제 금액, 지급 금액 등)을 판매자
      전용 시스템, 델리퀵 등을 통해 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자의 행위, 계약 이행 등이 거래 취소, 청구, 분쟁 또는 본 약관,
      이용정책 등의 위반을 야기한 것으로 판단되는 경우 또는 회사, 고객 또는
      제3자에게 위험을 초래한 것으로 판단되는 경우에는, 회사는 판매자에게 그에
      대한 금전적 보장 등의 제공을 요구할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 다음 각 호의 어느 하나에 해당하는 경우, 판매자에게 결제 금액의
      지급을 보류할 수 있습니다. 이와 관련하여, 해당 판매자가 판매한 상품이
      관련된 경우, 회사는 관련 상품의 판매 중지 등의 조치를 취할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. 고객이 상품을 신용카드로 구매한 경우, 회사는 여신전문금융업법의 관련
      조항에 따라 신용카드의 부당 사용으로 인한 잘못된 거래로 판단되는지 여부를
      확인하기 위해 최대 10영업일 동안 관련 결제 금액의 지급을 보류할 수
      있습니다. 이 경우 회사는 당해 판매자에게 거래의 유효성 확인을 위한 자료를
      제출하도록 요구할 수 있으며, 거래의 유효성을 확인한 후 결제 금액을
      판매자에게 지급할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      2. 자격을 갖춘 제3자가 가압류, 압류, 추심 등의 법원 명령에 따른 정당한
      권리를 기반으로 회사에 대해 판매자에 대한 결제금액의 지급 보류를 요청하는
      경우, 회사는 지급 보류에 대한 해당 요청이 철회되거나 정당한 채권자에게
      채무가 제공될 때까지 제3자에게 지급할 금액에 상당하는 결제금액의 일부를
      지급 보류할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      3. 금융 기관이 지급 불능 거래정지, 회생, 파산에 관한 절차를 시작하거나
      또는 이에 상응하는 기타 상당한 우려가 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      4. 판매자가 관련 법률 및 규정, 본 약관 또는 이용정책을 위반한 경우.
      <br class="calibre1" />
      <br class="calibre1" />
      5. 다른 사람의 권리에 대한 판매자의 침해로 인해, 정부 기관 또는 제3자가
      행정 행위를 취하거나, 조사를 시작하거나 민원을 제기하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      6. 상기 제3항에 따라 판매자가 판매에 적합하지 않은 상품을 판매한 것이
      밝혀지거나 또는 구매자 또는 제3자가 제기한 이의제기 또는 분쟁으로 인해
      환불, 교환, 청구, 신고 등의 위험이 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      7. 그 밖의 다른 합리적인 사유가 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 회사는 상기 제4항의 규정에 따라 조치를 취하고자 하는 경우에는, 그 취지
      및 그 사유를 판매자에게 통지하여야 하며, 판매자는 통지 후 2일 이내에
      회사에 관련 설명 자료를 제출하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 판매자가 제5항의 통지로부터 2일 이내에 설명 자료를 제출하지 아니하거나
      제출한 설명자료의 내용이 판매자의 합법성을 뒷받침하기에 충분하지 않은
      경우에는, 회사는 해당 원인이 소멸될 때까지 상기 제4항에 따른 조치를 계속할
      수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 12 조 (광고정보 게재)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 매장정보와 상품정보(이하, 광고 또는 해당 광고의 대상이 된 정보
      를 통칭하여 “광고정보”)를 구체적으로 입력하여 고객에게 정확한 정보를
      표시하여야 하며, 회사는 판매자가 등록한 정보가 부족하다고 판단하는 경우
      판매자에게 정보의 보완 또는 추가 입력을 요청할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 매장정보에는 매장명, 매장 주소, 매장 전화번호, 매장 운영시간,
      매장상세설명이 표시되어야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 영업정보는 메뉴, 가격, 원산지, 배달가능여부 정보를 포함합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 판매자가 게재를 신청한 광고정보를 검수기준에 따라 심사하고,
      신청한 정보가 광고정보 내에서 확인되는 경우 원칙적으로 광고 게재를
      승낙하며 이를 판매자에게 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 회사가 판매자의 광고정보 게재 신청을 승낙한 것이 광고정보가 위법하지
      않거나 약관, 검수기준, 이용안내 등에 적합함을 최종적으로 보증하거나
      보장하는 것은 아닙니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 판매자가 게재하는 광고정보는 쿠팡이츠 서비스 및 관련 프로모션 등에
      노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제,
      편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며,
      판매자는 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에
      대해 삭제, 비공개 등의 조치를 취할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 13 조 (판매자의 광고정보 관리의무)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자의 광고정보는 등록이 완료되는 때 노출되며, 판매자는 델리퀵(또는
      판매자 전용 시스템, 이하 동일함)에서 광고정보를 상시 확인 관리해야할
      의무가 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 광고정보를 변경하고자 하는 경우에는 델리퀵에 접속하여 직접
      수정하거나 델리퀵 고객센터 또는 영업 담당자를 통하여 변경할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자가 델리퀵에서 광고정보를 추가ㆍ삭제ㆍ변경 등 수정한 경우
      실시간으로 수정된 정보가 고객에게 노출되고, 이 경우 표시ㆍ광고의 공정화에
      관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 상표법, 저작권법 등
      관련 법령의 적용을 받으며 그에 대한 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 판매자가 회사의 델리퀵을 이용하지 않고, 외부업체의 사이트(프로그램 등)을
      이용하여 광고정보를 등록하는 경우, 이로 인해 발생하는 법적ㆍ기술적 문제에
      대한 모든 책임을 부담하여야 하고, 회사는 이에 대한 아무런 책임을 부담하지
      않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 판매자는 회사가 제공하는 양식에 광고정보를 등록하여야 하며, 양식을
      준수하지 않거나 허위등록 하는 경우 회사는 해당 상품의 노출을 즉시 중단할
      수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 14 조 (광고정보 수정요청 및 재게재)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자가 실제 매장정보 또는 영업정보와 다르거나 관련 법령, 약관,
      검수기준 등을 위반한 것으로 확인되는 경우, 회사는 판매자에게 일정한 기간을
      정하여 해당 정보의 삭제 또는 수정을 요청할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 전항에 따라 수정 요청을 받은 판매자는 정해진 기간까지 게재된 광고정보가
      관련 법령 또는 약관, 검수기준 등을 위반하지 않도록 수정해야 하며, 이를
      이행하지 않아 발생하는 불이익은 판매자가 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자가 본 조 제1항의 요청을 받았음에도 불구하고, 해당 정보를 수정하지
      않는 경우 회사는 판매자의 광고정보 게재 중단, 델리퀵 서비스 이용정지 등의
      조치를 취할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 관련 법령 또는 약관, 검수기준 등을 위반하는 사유를 해소한 판매자는
      고객센터 또는 영업 담당자를 통해 광고 재 게재 신청을 할 수 있고, 회사는
      해당 광고의 게재 여부에 대해 광고주에게 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 15 조 (광고정보 게재 제한)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 광고정보가 다음 각 호의 어느 하나에 해당하는 경우, 회사는 해당
      광고정보의 게재를 제한할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. 광고정보 노출 기간 중 매장이 폐업한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      2. 광고정보 등록 시 제출된 서류가 허위/위조로 확인된 경우
      <br class="calibre1" />
      <br class="calibre1" />
      3. 회사 및 델리퀵 서비스의 명예·평판·신용이나 신뢰도를 훼손하거나 훼손할
      우려가 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      4. 서비스의 품질을 저하시키거나 저하시킬 우려가 있는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      5. 등록된 주소정보, 전화번호가 허위이거나 사실여부가 증명되지 않는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      6. 배달가능지역의 설정이 “OO동 일부, OO동 100~230번지 등”과 같이 불명확한
      경우
      <br class="calibre1" />
      <br class="calibre1" />
      7. 광고정보에 게재된 가격과 매장 가격이 다른 경우
      <br class="calibre1" />
      <br class="calibre1" />
      8. 고객에게 추가 수수료를 요구하거나 정당한 사유 없이 고객의 주문을
      거부하는 경우
      <br class="calibre1" />
      <br class="calibre1" />
      9. 회사가 정당하고 합리적인 이유를 근거로 광고 게재 제한 등을 요청하는
      경우
      <br class="calibre1" />
      <br class="calibre1" />
      10. 판매자가 델리퀵 서비스 내에서 부정행위(서비스 어뷰징) 한 경우
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 광고정보의 게재를 제한하는 경우 원칙적으로 조치를 취하기 전에 그
      사유 및 조치할 내용을 판매자에게 통지합니다. 다만, 해당 광고정보가 법령을
      위반한 경우 게재 제한 등의 조치를 취한 후에 판매자에게 통지할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 광고정보의 게재를 제한하는 등의 경우 본 약관 또는 정책에서 정하는
      방법에 따라 판매자에게 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 16 조 (원산지 표시)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 원산지에 대한 정보를 표시하여야 하며, 원산지 정보를 누락하거나
      오 표시하는 경우 델리퀵 서비스 이용이 제한될 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 관련 법령에 따라 원산지 대한 정보를 입력, 표시해야 하고, 회사는
      원산지 표시를 하였는지 여부만을 확인하며 원산지 정보의 진정성 등에 대해
      어떠한 책임도 부담하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 17 조 (주류광고 등록 및 주류 판매)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자가 주류를 판매하고자 하는 경우 식품위생법 등 법령에 따라
      영업허가를 받은 장소에서 주류 판매업을 하는 자에 해당하여야 하며, 주세법
      규정에 따라 주류판매업면허 또는 의제주류판매업면허를 취득하여 주류를
      판매할 수 있는 권원을 확보해야 합니다. 이를 위반하는 경우 조세범처벌법에
      의거 법적 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 고객에게 음식과 함께 주류를 판매ㆍ배달하는 경우 판매자는 고객의
      성인인지 여부를 확인하여야 하며, 미성년자임을 알게 된 경우 주류를
      판매해서는 안 됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자가 전항을 위반하여 미성년자에게 주류를 판매한 때에는 청소년보호법
      및 식품위생법 등 관련 규정에 따라 법적 책임(형사처벌 및 영업정지 등)을
      부담합니다. 또한 회사는 판매자의 성인여부 미확인 및 청소년에게 주류를
      판매한 행위에 대해 어떠한 법적 책임도 부담하지 않으며, 판매자는 해당
      분쟁과 관련하여 회사를 면책시켜야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 판매자는 본 조 제1항에 따라 관련 법령을 위반하여 법적책임을 부담할 경우
      해당 사실 및 그 결과를 회사에 통지하여야 하며, 회사는 법률 위반행위
      판매자를 대상으로 광고정보 게재 중단 및 보류, 약관 해지를 할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 파트너가 배달 과정에서 고객이 미성년자임을 알게 되어 주류를 회수한 경우
      회수한 주류에 대해서는 고객에게 델리퀵 서비스 환불보상정책에 따라
      환불절차를 진행하도록 해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 18 조 (판매자의 서비스 제공)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 고객이 델리퀵의 사전 주문 또는 현장 주문 등의 방법으로 상품을
      구매할 수 있도록 서비스를 제공하여야 하고, 품질관리 및 고객서비스 등의
      서비스 제공에 따른 책임을 전적으로 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 고객에게 판매자의 일반 고객에게 판매하는 상품과 동일한 상품을
      판매하여야 하고, 델리퀵에 명시된 이용조건 이외의 사항으로 고객을 판매자의
      일반 고객과 차별하여 취급하여서는 안 됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 고객은 회사가 제공하는 온라인결제를 통하여만 결제할 수 있으며, 판매자는
      계약이행의 과정에서 현금 구매 유도 행위, 구매 취소 요구 또는 유도하는
      일체의 행위 등 부당한 방법으로 델리퀵 외의 다른 판매처로 고객을 유인하는
      행위를 하여서는 안 됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 판매자는 델리퀵 서비스를 대행하는 행위를 하여서는 안 됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 판매자는 델리퀵 서비스의 거래 매출 정보를 위조 또는 변조하여서는 안
      됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 판매자는 판매자 대표자 명의의 전자지급수단으로 물품의 판매 또는 용역의
      제공을 가장한 자기매출거래를 하여서는 안 되며, 자기 매출에 의한 거래는
      승인이 거절될 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 판매자는 정당한 사유 없이 다른 업체와 델리퀵과 동일 또는 유사한 서비스의
      계약을 체결하여 고객이 서비스를 이용하는데 불편을 초래해서는 안 됩니다. 단
      서비스의 특성상 다른 업체와 서비스 이용계약을 체결하여도 서비스의 품질이
      저하되지 않는 경우에는 예외로 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑧ 판매자는 본 계약이 해지된 후에도, 고객을 보호하기 위하여 해지일 현재
      아직 처리되지 않은 주문에 관한 필요한 조치를 취하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑨ 판매자의 사정으로 결제한 상품을 구매할 수 없거나 그 사용에 불편을 초래한
      경우(미공지 휴무, 재고부족, 시스템 오류, 폐업 등) 또는 상품을 구매할
      당시의 계약사항 및 이용조건과 실제 서비스 내용이 상이한 경우(상품 내용
      변경 및 오표기 등 계약내용의 임의적 변경, 일반고객과의 차별 등) 회사는
      임의로 고객에게 환불 처리하고, 환불에 소요되는 비용과 책임은 판매자가
      부담한다. 이 경우 판매자는 상품 판매대금의 10%(천원 이하인 경우 최소
      금액은 천원으로 함)를 고객보상금으로 회사에 지급하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑩ 본 약관에 따른 환불은 회사의 환불정책에 따릅니다. 다만, 서비스의 성격상
      판매자 환불정책의 적용이 필요한 경우 관련 법령에 위반되지 않는 범위 내에서
      판매자의 환불정책에 따를 수 있고, 판매자의 환불정책이 소비자분쟁해결기준,
      관련 법령 등에 위반되는 경우 회사는 이의 시정을 요청할 수 있으며, 판매자는
      즉시 이를 관계법령에 부합하도록 시정하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 19 조 (파트너 배달 서비스)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자가 고객에게 상품을 배달하는 경우 회사의 지정 파트너를 통해 배달할
      수 있으며, 수행할 인원을 직접 섭외, 고용하여 배달할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 상품이 훼손되지 않도록 안전하게 포장해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 파트너가 상품을 고객에게 배달하는 과정에서 상품이 훼손된 경우 귀책사유
      있는 파트너 또는 판매자가 상품 훼손에 대한 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 파트너 배달 서비스의 수수료는 델리퀵 또는 판매자 전용 시스템에
      공지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 20 조 (사용처리)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 판매한 내역을 델리퀵의 전산시스템 등에 실시간으로 등록 또는
      사용처리를 하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자의 사유로 판매 등록 또는 사용처리를 하지 않음으로 인하여 발생되는
      손해 및 그 정산에 관한 책임은 판매자가 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 21 조 (회사 및 판매자 간 관계)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사와 판매자는 서로 독립적이며, 본 약관은 회사와 판매자 간의 파트너쉽,
      합작투자, 판매업자, 프랜차이즈, 대리인, 고용 또는 독점 관계를 구성하지
      않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사의 사전 동의 없이 회사의 명칭, 상표, 로고 등을 자신의
      재량으로 사용할 수 없으며, 회사와 판매자 간의 관계를 표시, 홍보 또는
      과장할 수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 본 약관은 회사와 판매자 간의 관계에서만 유효하며, 제3자는 본 약관과
      관련하여 어떠한 권리, 청구 등을 주장할 수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 22 조 (정보의 제공 및 광고의 게재)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 델리퀵 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편
      등에 광고를 게재할 수 있습니다. 광고가 게재된 전사우편을 수신한 판매자는
      수신거절의 의사표시를 할 수 있습니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경,
      수정, 제한하는 등의 조치를 취하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 23 조 (권리의 귀속)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 델리퀵 서비스의 제공과 관련하여 발생한 저작물 등 일체의 유ㆍ무형
      결과물에 대한 저작권 등 지식재산권은 회사에 귀속됩니다. 단, 판매자의
      게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사가 델리퀵 또는 판매자 전용 시스템을 통해 판매자에게
      제공하는 통계자료 및 서비스 등에 대한 일체의 내용을 제3자에게 제공하거나
      외부에 유출할 수 없으며, 수정, 편집, 가공할 수 없습니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      제 24 조 (지식재산권 등)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사에 등록 또는 제공되는 모든 정보, 자료 등이 항상 정확하고 완전하며
      유효하다는 것을 보장하기 위해, 판매자는 필요한 경우 최신 정보, 자료 등을
      즉시 제공하거나 등록해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사에게 판매자의 상호, 상표, 사진, 문구, 컨텐츠 등(이하
      ‘자료’라 함)을 서비스를 홍보?판매를 위한 용도로 사용?복제?전시?유포?전송
      등을 할 수 있는 권한을 부여하며, 회사는 이러한 목적으로만 판매자의 자료를
      사용하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자는 델리퀵 및 판매자 전용 시스템에 게시한 게시물에 대한 저작권 등
      지식재산권을 합법적으로 소유하고 있으며, 회사의 서비스 판매 및 판매자의
      업체홍보에 있어서 지식재산권과 관련된 법령 등에 대한 위반이 없음을
      보증합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사가 제작한 자료에 관한 지식재산권은 회사에 있으며, 판매자가 이를 동의
      없이 사용하거나 사용 허락된 목적 범위 외에 잘못 사용한 경우 민?형사상 모든
      법적 책임을 부담합니다. 단, 판매자의 매장 내에서 회사가 제작한 자료를
      사용하고자 하는 경우, 판매자는 별도로 회사와 그에 관한 약정을 체결하여야
      합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 제3자가 게시물이 자신의 권리를 침해한다며 분쟁을 제기하는 경우 판매자는
      본인의 책임과 비용으로 해당 분쟁을 해결하며 회사를 면책하여야 합니다.?
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 회사는 권리 침해를 주장하는 제3자가 판매자의 정보 공개를 요청하는 경우
      판매자에 대한 정보를 공개를 할 수 있습니다. 이와 관련하여 회사가 제3자에게
      손해배상책임을 부담하는 경우 판매자는 회사의 손해배상액 및 그 배상과
      관련된 부대비용(이자 및 변호사 보수 등의 방어비용 포함) 등을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 판매자는 본 약관에서 정한 업무와 관련하여 회사가 사전에 허용한 각종
      표지물 등의 양식만을 사용하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑧ 판매자는 본 약관이 해지되거나 종료된 경우에는 대여 받은 각종 표지물 등을
      원상태로 반환하고 델리퀵 거래와 관련된 모든 광고물 및 표지물을 즉시
      제거하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑨ 본 조에 따른 의무는 본 약관이 종료된 후에도 회사 및 판매자에 대하여
      유효하게 존속됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 25 조 (판매자의 보증)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 델리퀵에 명시된 서비스에 대하여 관련 법령에 따라 사업자등록 및
      인ㆍ허가 등을 구비하고 적법하게 서비스를 제공할 수 있음을 보증하며, 해당
      서비스의 판매에 따른 세금을 납부할 의무가 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 자신이 제공하는 서비스가 관련 법령상 델리퀵 통해 대중에게
      소개되는 것이 제한되거나 금지된 상품이 아님을 보장하고, 회사의 고의 또는
      중과실로 인한 경우를 제외하고 회사가 판매자의 서비스를 회사의 웹사이트에
      소개하는 행위로 인해 발생할 수 있는 모든 책임으로부터 회사를 면책시키고,
      그로 인한 손해를 배상해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 본 조항에 따른 의무는 본 약관의 종료 후에도 회사와 판매자에 대하여
      유효하게 존속됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 26 조 (권리보호)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 상표권/서비스표권의 존재 여부 및 효력범위에 관하여 임의로
      판단하지 않으며, 상표권/서비스표권을 사전에 보호하거나 대신 행사하지
      않습니다. 단, 타인의 상표권/서비스표권을 침해하거나 침해할 우려가 있다는
      법원의 판결/결정/명령문, 기타 관련 국가기관의 유권해석 등이 제출되는 경우,
      지체 없이 해당 광고정보의 게재를 중단할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 상표권/서비스표권을 보유한 자는 자신의 권리가 침해되었을 경우 먼저
      침해한 자를 상대로 광고정보 게재 중지요청, 권리침해에 해당하는 기재의
      삭제요청 등의 권리행사를 해야 합니다. 회사는 자신의 상표권/서비스표권이
      침해되었음을 주장하면서 일정한 광고정보의 게재중단을 요청해 오는 자(이하
      “요청인”)가 있는 경우, 해당 요청인에게 상표권/서비스표권의 존재를 소명할
      수 있는 서류 등의 제출을 요청할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 요청인의 권리를 침해하고 있다고 지적된 판매자가 해당 광고정보의
      게재 또는 해당 기재가 적법한 권리 또는 권한에 의해 행하여진 것임을
      소명하지 못했을 경우 해당 광고정보의 게재를 즉시 중단할 수 있으며, 만약
      소명하였을 경우라면 지체 없이 요청인에게 이러한 사정을 통지합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 부정경쟁방지 및 영업비밀의 보호에 관한 법률 상의 부정경쟁행위의
      존재 여부 및 그 범위에 관하여 임의로 판단하지 않으며, 부정경쟁행위로부터
      광고주 등을 사전에 보호하거나 동 행위의 중단을 요청하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 27 조 (개인정보보호)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 델리퀵 서비스를 통해 알게 된 고객의 개인정보를 배송 등 본
      약관에 따른 델리퀵 서비스를 이용하기 위한 목적을 포함하여 회사가
      고객으로부터 받은 개인정보 수집·이용 및 제3자 제공 동의서상의 제3자 제공
      목적 이외의 용도로 사용할 수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 개인정보보호를 위하여 배송 등의 목적으로 판매자에게 제공된 고객의
      개인정보를 상당 기간이 지난 후 비공개 조치할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자가 고객의 개인정보를 유출하거나 유용한 경우 회사는 일체의 책임을
      지지 아니하며, 판매자가 그에 대한 모든 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 판매자는 회사로부터 제공받은 고객의 개인정보를 제공받은 목적(배송, CS
      등)의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 해당 목적을
      달성하거나 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한 제공받은
      고객의 개인정보의 주체로부터 직접 파기 요청을 받은 경우 즉시 이에 응해야
      합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 판매자는 관련 법령 및 방송통신위원회 고시에 따라 회사로부터 제공받은
      고객의 개인정보를 보호하기 위하여 기술적?관리적 보호조치를 취해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 28 조 (비밀유지)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 델리퀵 서비스 사용 중 알게 된 회사 또는 델리퀵 서비스에 관한
      모든 정보(서비스 이용 정보, 직접 또는 간접적으로 취득한 기술 및 사업 정보,
      고객 정보 및 문서, 전자 파일 등과 같은 형식을 취하는 기타 그 밖의 정보, 본
      약관에서 명시된 당사자들의 권리 의무에 관한 세부사항 등을 포함하나 이에
      제한되지 않음, 이하 “기밀정보”)를 본 약관의 이행, 델리퀵 서비스의 사용,
      델리퀵 서비스를 통한 고객과의 거래 수행 등 본 약관에서 정한 목적 이외의
      목적으로 사용해서는 안 됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 모든 기밀정보가 회사 소유이며 자산 가치를 지니고 있음을
      인정하고, 본 약관에서 달리 명시되지 아니하는 한 기밀정보 보호를 위해
      합리적인 조치를 취해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자는 회사의 사전 서면 동의 없이 기밀정보를 복사, 복제 또는
      가공하거나 제3자에게 제공, 판매, 홍보 또는 공개할 수 없으며, 고의 또는
      과실로 인한 제3자에 대한 기밀 정보 유출에 대하여 모든 책임을
      부담합니다(제3자에 대한 손해 및 복구를 포함하나 이에 제한되지 않음).
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 본 조에 따른 의무는 본 약관 및 개별 서비스의 종료 후에도 회사와 판매자에
      대하여 유효하게 존속됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 29 조 (ID 및 비밀번호 관리)
      <br class="calibre1" />
      <br class="calibre1" />
      ① ID 및 비밀번호는 서비스를 이용하기 위해 판매자 전용 시스템(또는 회사가
      제공하는 다른 툴)에 접속하기 위한 기간 동안에만 사용될 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자는 회사의 귀책 사유가 아닌 사유로 인하여 발생한 ID 또는 비밀번호의
      유출, 양도 또는 대여로 인해 발생하는 모든 손실 및 손해에 대하여 책임을
      부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자가 자신의 ID나 비밀번호가 도난당하거나 권한 없는 제3자에 의해
      사용되었다는 것을 인지한 경우, 판매자는 즉시 이를 회사에 통지하여야
      합니다. 이 경우, 회사는 신속하게 해당 문제를 해결하기 위해 최선을
      다합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 30 조 (판매자 개인정보 보호)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 판매자가 동의한 서비스 운영 이외의 목적으로 판매자가 서비스
      이용을 위해 제공한 개인정보를 이용하지 않습니다. 회사가 그러한 정보를
      새로운 목적으로 사용하거나 제3자에게 제공하고자 하는 경우에는 그러한 사용
      또는 제공의 목적 등을 판매자에게 통지하고, 해당 사용 또는 제공 시점에
      판매자로부터 별도의 동의를 얻어야 합니다. 단, 관련 법령 및 규정에서 달리
      정하고 있는 경우에는 그러하지 아니합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 판매자가 개인정보의 수집, 이용 및 제공에 대한 동의를 거부할 경우
      이용이 제한되는 서비스를 미리 특정하여야 하며, 서비스 운영에 필수적이지
      않은 개인정보의 수집, 이용 및 제공에 대한 동의를 거부한 판매자에 대해서
      서비스 이용 약관을 제한하거나 거부해서는 안됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 판매자의 개인정보를 제3자에게 제공할 필요가 있는 경우, 판매자의
      개인정보를 보호하기 위하여 회사는 관련 법령이 정하는 바에 따라 판매자의
      동의를 얻어야 하며, 회사가 판매자의 개인정보 처리를 제3자에게 위탁하는
      경우, 회사는 “판매자 개인정보보호 정책(필요한 경우, 본 정책은 서비스
      유형에 따라 다를 수 있음)”을 통해 위탁 관련 세부 사항을 공개해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 기타 판매자 개인정보의 보호 및 사용에 관한 사항에 대해서는 관련법 및
      회사의 개인정보취급방침이 적용됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 31 조 (양도의 금지 등)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 판매자는 회사의 사전 서면 동의 없이 본 약관에 따른 권리 및 의무를
      제3자에게 양도할 수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 본 약관은 회사의 동의를 받은 판매자 및 판매자의 승계인 및 양수인에
      대하여 법적으로 구속력을 가집니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 판매자에 대한 서면통지(전자메일을 포함함)로써 본 약관상의 권리,
      의무를 회사의 계열회사(독점규제 및 공정거래에 관한 법률상의 정의를 따름)에
      양도할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 32 조 (윤리규정 준수)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사와 판매자는 공정하고 투명한 윤리경영을 추진하여 상호간의 신뢰를
      구축하고 지속 가능한 발전을 위해 다음의 의무사항을 준수해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      1. 회사는 판매자 및 관계자 혹은 제3자와 거래 시 어떠한 경우에도 금품,
      선물, 향응, 편의 또는 접대를 요구하거나 받는 등의 행위를 하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      2. 판매자는 회사와의 계약 이행과정에서 불공정행위를 하거나 금품, 선물,
      향응, 편의 또는 접대 등을 요구하거나 제공하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      3. 판매자는 회사의 임직원 및 관계자 혹은 제3자가 대가를 요구하면서
      고의적으로 업무를 지연하거나 불이익을 제공하는 등 부도덕한 행위를 하는
      경우에는 회사에 즉시 신고하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사와 판매자는 본 약관, 회사와 판매자 간의 제반 약정사항 및 법령을
      준수하며, 판매자는 회사의 임직원, 기타 관련자 및 이해관계자와 부정한
      목적으로 본 약관에 따른 서비스를 악용하지 않을 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사 및 판매자가 본 조에 따른 의무를 위반한 경우 위반 당사자는 상대방
      당사자에 대하여 위반내용에 따른 거래 중지, 계약 해지 등의 책임을
      부과합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 제3항에 의하여 거래중지, 계약해지 등의 거래제한 조치를 받은 당사자는
      거래 제한 조치를 취한 당사자에 대하여 손해배상청구 및 기타 민ㆍ형사상의
      책임, 행정상의 책임의 일체의 이의를 제기하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 33 조 (손해배상)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사 또는 판매자가 본 약관에서 정한 사항을 위반함으로써 상대방에게
      손해가 발생한 경우 귀책사유 있는 일방은 상대방에게 발생한 모든 손해(변호사
      보수 등의 방어비용 포함)를 배상하여야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 판매자의 귀책사유로 인해 회사에 손해가 발생한 경우 회사는 판매자에게
      지급할 대금에서 손해액을 공제할 수 있으며, 판매자의 회사에 대한 채무는
      회사가 판매자에게 지급할 대금에서 즉시 상계 처리할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 34 조 (회사의 권한)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 델리퀵 및 서비스의 내용, 외관, 디자인, 기능 및 기타 양상을 결정할
      수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 본 약관에서 명시된 제한된 목적을 위해 필요한 경우가 아니면 판매자
      및 고객의 대리인으로 행동하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 델리퀵 서비스와 관련하여 사용 가능하거나 제공된 모든 컨텐츠, 소프트웨어,
      기능, 자료 및 정보를 포함하여, 델리퀵은 “현상 그대로” 제공됩니다. 델리퀵
      서비스의 판매자로서 판매자는 델리퀵 및 판매자 전용 시스템을 자신의 책임
      하에 사용합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 35 조 (회사의 면책)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 회사는 판매자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
      정확성 등의 내용에 관하여는 고의 또는 중과실이 없는 한 책임을 지지
      않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 회사는 판매자에게 델리퀵 서비스를 제공하며, 판매자가 등록한 상품, 서비스
      등의 정보 또는 고객과의 거래와 관련하여 분쟁이 발생하는 경우 판매자가 해당
      분쟁의 결과에 대해 온전히 책임을 부담합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ③ 회사는 판매자에 대한 정보를 열람할 수 있는 방법을 고객에게 제공할 수
      있으며, 판매자는 해당 정보를 입력하지 못하거나 허위 정보를 입력함으로
      인하여 발생하는 법적 문제에 대한 모든 책임을 부담해야 합니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ④ 회사는 본 조 제1항 및 제2항과 관련하여 제3자에게 손해를 배상하여 주거나
      기타 비용을 지출한 경우에는 판매자에 대해 구상권을 행사할 수 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑤ 정당한 권한을 위임 받은 자의 요청이 있는 경우, 회사는 해당 판매자의 상품
      판매 등의 행위를 제한 또는 정지할 수 있고, 판매자는 회사의 고의 또는
      중과실이 없는 한 이로 인하여 발생하는 손해와 관련하여 회사에 대해
      손해배상을 청구할 수 없습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑥ 컴퓨터를 포함한 IT장비와 관련된 수리, 검사, 교환, 고장, 통신두절 등을
      포함한 천재지변 또는 이에 준하는 불가항력적인 상황이 발생한 경우, 회사는
      델리퀵 서비스의 제공을 일시적으로 중단할 수 있고, 해당 상황이 회사의 고의
      또는 중대한 과실에 기인하지 않는 한, 그에 대한 책임을 부담하지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑦ 회사는 판매자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 고의 또는
      중과실이 없는 한 책임을 지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑧ 회사는 판매자의 주의의무 위반으로 발생되는 사고, 분쟁 등에 대하여 고의
      또는 중과실이 없는 한 책임을 지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑨ 회사는 판매자의 자기 정보 또는 고객 정보의 제3자 제공 또는 유출과
      관련하여 회사의 귀책사유가 없는 한 판매자, 고객 또는 제3자에 대하여 책임을
      지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑩ 판매자가 관련 법령 및 규정의 제?개정안, 이용정책, 판매자에게 통지된 사항
      등과 관련하여 주의의무를 다하여야 하며, 판매자의 과실로 인해 판매자에게
      발생한 손해에 대해서 회사는 책임지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑪ 회사는 관련 법령이 허용하는 한도 내에서 본 약관 및 델리퀵 서비스로 인해
      발생하는 투자 손실, 이익 손실, 사업 기회 손실 등에 대해 어떠한 보증도
      제공하지 않으며, 회사는 고의 또는 중과실이 없는 한 이에 대해 책임을 지지
      않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ⑫ 회사는 무료로 제공되는 서비스 이용과 관련하여 관계 법령에 특별한 규정이
      없는 한 책임을 지지 않습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      제 36 조 (준거법 및 관할)
      <br class="calibre1" />
      <br class="calibre1" />
      ① 본 약관에 대한 회사와 판매자 간의 해석 차이로 인하여 발생하는 모든
      분쟁은 대한민국 법에 따라 규율됩니다.
      <br class="calibre1" />
      <br class="calibre1" />
      ② 본 약관 및 델리퀵 서비스 이용으로 인하여 회사 및 판매자 간에 발생하는
      분쟁에 관한 소송은 서울중앙지방법원에 관할권이 있습니다.
      <br class="calibre1" />
      <br class="calibre1" />
      [부칙]
      <br class="calibre1" />
      <br class="calibre1" />본 약관은 2019년 10월 05일부터 시행됩니다.
    </Div>
  );
};

export default Terms;
