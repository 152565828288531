const SET_USER = "user/SET_USER";

export const setUser = value => ({ type: SET_USER, payload: value });

const initialState = {
  user: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default user;
