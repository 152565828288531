import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import { formatPrice, dateToWords, statusToString } from '../../common/format';
import Loader from '../../components/Loader';
import SendSms from '../../components/SendSms';

const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-size: 14px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  .order-bold {
    font-size: 12px;
    color: #aaaaaa;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    height: 48px;
    width: 90%;
    margin: 0 5%;
    &:last-child {
      border-bottom: none;
    }
  }
  .between {
    justify-content: space-between;
  }
  .border-top {
    padding: 0.5rem 0;
    border-top: 1px solid #e6e6e6;
  }
  .container-button {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 24px;
    width: 90%;
    margin: 1rem 5%;
    cursor: pointer;
  }
`;
const Table = styled.table`
  margin: 0 5%;
  width: 90%;
  border-collapse: collapse;
  th {
    border-bottom: 1px solid #e6e6e6;
    padding: 0.25rem 0;
  }
  td {
    text-align: center;
    padding: 0.25rem 0;
  }
`;

const HotelOrderDetail = props => {
  const { orderNumber } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [store, setStore] = useState();

  useEffect(() => {
    db.collection('orders')
      .doc(orderNumber)
      .onSnapshot(doc => {
        if (!data) return;
        if (data.status !== doc.data().status) setData(doc.data());
      });
  }, [db, orderNumber, data]);

  useEffect(() => {
    db.collection('orders')
      .doc(orderNumber)
      .get()
      .then(doc => {
        db.collection('stores')
          .doc(doc.data().store_id)
          .get()
          .then(doc => setStore(doc.data()));
        setData(doc.data());
      })
      .catch(err => console.log(err));
  }, [db, orderNumber]);

  const updateResult = result => {
    return db
      .collection('orders')
      .doc(orderNumber)
      .update({ smsResult: result, status: 4 });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {data && store ? (
        <Container>
          <div className="flex-row between" style={{ height: '32px' }}>
            <div className="order-bold">#{data.orderNumber}</div>
            <div style={{ fontSize: '12px', color: '#aaa' }}>{dateToWords(data.date.toDate())}</div>
          </div>
          <div className="flex-row">
            <b>배달업체명</b> &nbsp;&nbsp;
            {store.realName}
          </div>
          <div className="flex-row">
            <b>배달업체번호</b> &nbsp;&nbsp;
            {store.phone}
          </div>
          <div className="flex-row">
            <b>주문상태</b> &nbsp;&nbsp;{statusToString(data.status)}
          </div>
          {data.roomNo && (
            <div className="flex-row">
              <b>객실번호</b> &nbsp;&nbsp;{data.roomNo}
              {data.status > 2 && (
                <SendSms
                  style={{ marginLeft: 'auto' }}
                  updateResult={updateResult}
                  defaultStatus={data.smsResult}
                  data={data}
                />
              )}
            </div>
          )}

          <Table>
            <thead>
              <tr>
                <th style={{ width: '55%' }}>메뉴</th>
                <th style={{ width: '15%' }}>수량</th>
                <th style={{ width: '30%' }}>금액</th>
              </tr>
            </thead>
            <tbody>
              {data.cart.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ textAlign: 'left' }}>{item.name}</td>
                    <td>{item.counter}</td>
                    <td style={{ textAlign: 'right' }}>{formatPrice(item.total)}원</td>
                  </tr>
                  {item.options &&
                    item.options.length > 0 &&
                    item.options.map((option, index) => (
                      <tr
                        key={index}
                        style={{
                          borderLeft: '1px solid #e6e6e6',
                          color: '#aaa',
                          textAlign: 'left',
                          fontSize: '13px'
                        }}
                      >
                        <td style={{ textAlign: 'left' }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {option.realName} (+{formatPrice(option.price)})
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <div
            className="flex-row"
            style={{
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '0 5%',
              width: '90%',
              borderTop: '1px solid #e6e6e6'
            }}
          >
            <div>총 금액</div>
            <div>{formatPrice(data.total)}원</div>
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default HotelOrderDetail;
