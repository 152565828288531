import React from 'react';
import icon from '../images/table.svg';

const ButtonToSheet = ({ onClick }) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        padding: '0.2rem 0.3rem',
        marginLeft: '1rem'
      }}
      onClick={onClick}
    >
      <img src={icon} style={{ width: '14px', marginTop: '4px' }} alt="엑셀다운" />
    </div>
  );
};

export default ButtonToSheet;
