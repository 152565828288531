import React, { useEffect, useContext, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AcceptItem from './AcceptItem';
import { FirebaseContext } from '../../firebase';
import { asyncAll } from '../../common/request';
import { sortBy } from 'lodash';
import Loader from '../../components/Loader';
import { MdAutorenew } from 'react-icons/md';

const AcceptList = props => {
  const { user } = useSelector(state => state.user);
  const { db } = useContext(FirebaseContext);
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState([]);
  const [reload, setReload] = useState(false);

  // 불러오는 기준 (진행중인 주문)
  const loadData = useCallback(
    id => {
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return new Promise(resolve => {
        db.collection('orders')
          .where('store_id', '==', id)
          .where('date', '>=', yesterday)
          .get()
          .then(snapshots => {
            let data = [];
            snapshots.forEach(doc => data.push(doc.data()));
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            setError([...error, err]);
          });
      });
    },
    [db, error]
  );

  const loadAll = useCallback(() => {
    const today = new Date();
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

    return db
      .collection('orders')
      .where('date', '>=', yesterday)
      .orderBy('date', 'desc')
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push(doc.data()));
        setOrderData(result.filter(item => item.storeStatus && item.storeStatus !== '등록'));
      });
  }, [db]);

  useEffect(() => {
    let isCancel = false;
    if (user.businessType === '숙박') props.history.push('/accept/hotel');
    // 1. user의 bn에 속하는 store 목록을 구한다.
    // user에 (미리 넣어놓음) 있는 store 목록을 가져온다.
    const storeIds = user && user.group;

    if (storeIds) {
      setOrderData(null);
      const promises = storeIds.map(id => loadData(id));
      asyncAll(promises).then(result => {
        // 시간순으로 정렬
        const flatedArr = result.reduce((acc, val) => acc.concat(val), []);
        !isCancel && setOrderData(sortBy(flatedArr, 'date').reverse());
      });
    }

    if (user.authLevel === 'ROOT') {
      setOrderData(null);
      loadAll();
    }

    return () => (isCancel = true);
  }, [db, loadData, loadAll, reload]); // eslint-disable-line

  return (
    <>
      {orderData ? (
        <>
          <div
            style={{
              background: '#e6e6e6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              fontSize: '14px',
              color: '#55607d',
              position: 'sticky',
              top: '47px'
            }}
          >
            <span>전일 0시 부터 현재까지 주문 </span>
            <MdAutorenew size="16px" onClick={() => setReload(!reload)} />
          </div>
          {orderData.length === 0 && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                marginTop: '1rem'
              }}
            >
              주문 정보가 없습니다.
            </div>
          )}
          {!orderData && <Loader />}
          {orderData.map((item, index) => (
            <AcceptItem data={item} key={index} />
          ))}
          <div style={{ height: '47px' }}></div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRouter(AcceptList);
