import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  formatPrice,
  dateToWords,
  statusToString,
  statusToColor,
  getAllTotal
} from '../../common/format';
import addressIcon from '../../images/address.svg';
import foodIcon from '../../images/food.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  .order-bold {
    font-size: 12px;
    color: #aaaaaa;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    height: 48px;
    width: 90%;
    margin: 0 5%;
  }
  .between {
    justify-content: space-between;
  }
  .border-top {
    padding: 0.5rem 0;
    border-top: 1px solid #dfdfdf;
  }
`;
const Status = styled.div`
  padding: 0.8rem 0;
  text-align: center;
  background: ${props => props.background};
  width: 100%;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;
`;

const AcceptItem = ({ data }) => {
  return (
    <Link to={`/accept/detail/${data.orderNumber}`}>
      <Container>
        <div className="flex-row between" style={{ height: '32px' }}>
          <div className="order-bold">#{data.orderNumber}</div>
          <div style={{ fontSize: '12px', color: '#aaa' }}>{dateToWords(data.date.toDate())}</div>
        </div>
        <div className="flex-row">
          <img src={addressIcon} alt="" />
          {data.address}
        </div>
        <div
          className="flex-row"
          style={{
            height: 'fit-content',
            alignItems: 'flex-start',
            padding: '12px 0',
            border: 'none'
          }}
        >
          <img src={foodIcon} alt="" />
          <div style={{ display: 'flex', flexDirection: 'column', border: 'none' }}>
            <div>
              [메뉴 {data.cart.length}개] {formatPrice(getAllTotal(data.cart, data.deliveryFee))}원
            </div>
            <div>
              {data.cart[0].realName} {data.cart.length > 1 && `외 ${data.cart.length - 1}개`}
            </div>
          </div>
        </div>
        <Status
          background={statusToColor(data.status).background}
          color={statusToColor(data.status).color}
        >
          {statusToString(data.status)}
        </Status>
      </Container>
    </Link>
  );
};

export default AcceptItem;
