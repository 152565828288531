import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import Input from '../components/Input';
import Button from '../components/Button';
import { FirebaseContext } from '../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { setUser } from '../redux_modules/user';
import { checkBn } from '../common/format';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  a {
    text-decoration: underline;
    color: black;
  }
  label {
    margin-top: 0.5rem;
    font-size: 14px;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
    text-align: center;
    width: 200px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;
const Step = styled.div`
  padding: 0.2rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff3250;
  border-radius: 14px;
  color: white;
  font-weight: bold;
`;

const Join = (props) => {
  const { auth, db, storage } = useContext(FirebaseContext);
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState();
  const [data2, setData2] = useState({ email: (auth.currentUser && auth.currentUser.email) || '' });
  const [agreement, setAgreement] = useState(false);
  const { step } = props.match.params;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const active = useMemo(() => {
    if (agreement) {
      return true;
    }
    return false;
  }, [agreement]);

  const onChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeInput2 = (e) => {
    setData2({
      ...data2,
      [e.target.name]: e.target.value,
    });
  };
  const onFileChange = (e) => {
    if (e.target.files[0].size > 10 * 1024 * 1024) {
      alert('10MB 이하의 파일만 업로드 가능합니다.');
      return null;
    }
    setData2({
      ...data2,
      [e.target.id]: e.target.files[0].name,
    });
    setFiles(
      files.concat({
        name: e.target.id,
        file: e.target.files[0],
      })
    );
  };

  const checkRule = (data) => {
    const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const phoneRule = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

    if (!data.email || !emailRule.test(data.email)) {
      alert('이메일주소를 확인해주세요.');
      return false;
    } else if (!data.pw) {
      alert('비밀번호를 입력하세요');
      return false;
    } else if (data.pw.length < 8) {
      alert('비밀번호는 숫자와 문자포함 8자리 이상입니다.');
      return false;
    } else if (data.pw && data.pw !== data.pw2) {
      alert('비밀번호 확인이 일치하지 않습니다.');
      return false;
    } else if (!data.name) {
      alert('이름을 입력해주세요');
      return false;
    } else if (!phoneRule.test(data.phone)) {
      alert('핸드폰번호를 확인해주세요. (숫자만 입력)');
      return false;
    } else {
      return true;
    }
  };

  const checkRule2 = (data) => {
    if (!checkBn(data.bn)) {
      alert('사업자번호가 올바르지 않습니다.');
      return false;
    } else if (!data.bankSort || !data.bankName || !data.account || !data.accountOwner) {
      alert('정산 정보는 필수 입력입니다.');
      return false;
    } else {
      return true;
    }
  };

  // auth 회원가입
  const onRegist = (data) => {
    auth
      .createUserWithEmailAndPassword(data.email, data.pw)
      .then((result) => {
        const { uid } = result.user;
        db.collection('users')
          .doc(uid)
          .set({
            email: data.email,
            name: data.name,
            phone: data.phone,
            step: 1, // 1. 회원가입 완료, 2. 추가정보 입력 완료
            createdAt: new Date(),
            isAdminValid: false, // 관리자 승인 여부 (기본: false)
            level: 'partner',
          })
          .then((result) => {
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err)
        if(err.code === 'auth/email-already-in-use'){
          alert('이미 가입된 이메일 입니다.')
        } else {
          alert(err.message)
        }

      });
  };

  const onEdit = async () => {
    // console.log(files);
    setLoading(true);
    const promises = files.map(
      (file) =>
        new Promise((resolve) => {
          storage
            .ref()
            .child(`users/${user.uid}/` + file.file.name)
            .put(file.file)
            .then((result) => {
              result.ref.getDownloadURL().then((url) => {
                resolve({ name: file.name, url });
              });
            })
            .catch((err) => console.log(err));
        })
    );

    const result = await Promise.all(promises);
    let newData = { ...data2 };
    delete newData.email;
    result.forEach((item) => Object.assign(newData, { [item.name]: item.url }));
    db.collection('users')
      .doc(user.uid)
      .update({
        ...newData,
        step: 2,
      })
      .then(() => {
        setLoading(false);
        dispatch(
          setUser({
            ...user,
            ...newData,
            step: 2,
          })
        );
        props.history.push('/info');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <br />
      <Step>Step {step}</Step>
      {step === '1' && (
        <>
          <h4>델리퀵 회원이 되어보세요.</h4>
          <Input placeholder="이메일" name="email" onChange={onChangeInput} />
          <Input placeholder="비밀번호" name="pw" onChange={onChangeInput} type="password" />
          <Input placeholder="비밀번호 확인" name="pw2" onChange={onChangeInput} type="password" />
          <Input placeholder="이름" name="name" onChange={onChangeInput} />
          <Input placeholder="휴대폰번호(숫자만 입력)" name="phone" onChange={onChangeInput} />
          <div
            style={{
              fontSize: '12px',
              width: '232px',
              marginTop: '0.5rem',
            }}
          >
            <input
              type="checkbox"
              name="agreement"
              onChange={(e) => {
                setAgreement(e.target.checked);
              }}
            />
            <a href="/terms/1">델리퀵 이용약관</a>, <a href="/terms/2">개인정보 처리방침</a>을 확인하였으며 이에
            동의합니다.
          </div>
          <Button
            onClick={() => {
              if (checkRule(data)) {
                console.log(onRegist(data));
              }
            }}
            active={active}
          >
            동의하고 가입하기
          </Button>
        </>
      )}
      {step === '2' && (
        <>
          <h4>추가정보를 입력해주세요. (필수)</h4>
          <b style={{ fontSize: '14px' }}>업종을 선택해주세요.</b>
          <div
            style={{
              fontSize: '14px',
              paddingTop: '0.5rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <input type="radio" name="businessType" value="숙박" onChange={onChangeInput2} />
            <span style={{ marginLeft: '0.2rem', marginRight: '0.5rem' }}>숙박업체</span>
            <input type="radio" name="businessType" value="배달" onChange={onChangeInput2} />
            <span style={{ marginLeft: '0.2rem' }}>배달업체</span>
          </div>
          <Input placeholder="사업자 등록증 기재된 상호" name="businessName" onChange={onChangeInput2} />
          <Input placeholder="사업자등록번호(숫자만 입력)" name="bn" onChange={onChangeInput2} />
          <Input placeholder="생년월일(숫자만 입력)" name="birth" onChange={onChangeInput2} />
          <br />
          <b style={{ fontSize: '14px' }}>정산에 필요한 정보를 입력해주세요.</b>
          <div
            style={{
              fontSize: '14px',
              paddingTop: '0.5rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <input type="radio" name="bankSort" value="개인" onChange={onChangeInput2} />
            <span style={{ marginLeft: '0.2rem', marginRight: '0.5rem' }}>개인</span>
            <input type="radio" name="bankSort" value="법인" onChange={onChangeInput2} />
            <span style={{ marginLeft: '0.2rem' }}>법인</span>
          </div>

          <Input placeholder="은행" name="bankName" onChange={onChangeInput2} />
          <Input placeholder="계좌번호" name="account" onChange={onChangeInput2} />
          <Input placeholder="예금주" name="accountOwner" onChange={onChangeInput2} />
          <br />
          <label htmlFor="file-1">사업자등록증 ({data2[`file-1`] ? data2[`file-1`] : '+업로드'})</label>
          <input accept=".jpg, .png, .pdf" type="file" id="file-1" onChange={onFileChange} />
          <label htmlFor="file-2">통장사본 ({data2[`file-2`] ? data2[`file-2`] : '+업로드'})</label>
          <input accept=".jpg, .png, .pdf" type="file" id="file-2" onChange={onFileChange} />
          <label htmlFor="file-3">신청서 ({data2[`file-2`] ? data2[`file-3`] : '+업로드'})</label>
          <input accept=".jpg, .png, .pdf" type="file" id="file-3" onChange={onFileChange} />
          <div style={{ fontSize: '12px', width: '232px', marginTop: '0.2rem' }}>
            10MB이하의 jpg, png, pdf 파일만 가능합니다.
          </div>
          <br />
          <Button
            active={true}
            onClick={() => {
              if (checkRule2(data2)) {
                console.log(data2);
                onEdit();
              }
            }}
          >
            {loading ? <Spinner /> : '완료'}
          </Button>
          <div
            style={{ marginTop: '2rem', color: '#aaa', fontSize: '14px' }}
            onClick={() => {
              auth.signOut();
              props.history.push('/login');
            }}
          >
            로그아웃
          </div>
        </>
      )}
    </Container>
  );
};

export default withRouter(Join);
