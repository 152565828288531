import React from "react";
import styled from "styled-components";

const Container = styled.input`
  font-size: 14px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: ${props => (props.disabled ? "#ccc" : "black")};
  text-align: center;
  min-width: 180px;
  width: 100%;
  &:focus {
    box-shadow: 0 0 5px 1px #ff3250;
  }
`;

const Input = ({
  title,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  name,
  style,
  value,
  type,
  error
}) => {
  return (
    <>
      <div
        style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}
      >
        {title && <div style={{ width: "80px" }}>{title}</div>}
        <Container
          style={{ ...style }}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          value={value}
        />
      </div>
      {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>}
    </>
  );
};

export default Input;
