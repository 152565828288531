import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const OrderSelect = ({ data }) => {
  return (
    <Container>
      {data.map((item, index) => (
        <StoreItem data={{ ...item, index }} />
      ))}
    </Container>
  );
};

export default OrderSelect;

const Item = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .image {
    background-image: ${props => `url(${props.img})`};
    background-size: cover;
    background-position: center center;
    width: 40%;
    margin: 0.5rem 0.5rem;
    height: 120px;
  }
  .col {
    margin: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
  }
`;

const StoreItem = ({ data }) => {
  return (
    <Link to={`/order/${data.id}`}>
      <Item img={data.photo}>
        {/* <div className="image" /> */}
        <div className="col">
          <div style={{ color: "black" }}>
            {data.index + 1}. {data.name}
          </div>
          <div style={{ color: "gray", fontSize: "14px" }}>{data.phone}</div>
        </div>
      </Item>
    </Link>
  );
};
