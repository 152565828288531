import React, { useEffect, useContext, useState } from "react";
import { FirebaseContext } from "../../firebase";
import OrderSelect from "./OrderSelect";
import styled from "styled-components";
import Loader from "../../Icons/Loader";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  .info {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
    padding: 1rem 1rem;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2rem;
    .title {
      margin-right: 1rem;
      font-weight: 400;
      color: black;
    }
    .main-title {
      color: black;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }
`;

const Order = () => {
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState();
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    if (user) {
      db.collection("stores")
        .where("bn", "==", user.bn)
        .get()
        .then(snapshots => {
          let stores = [];
          snapshots.forEach(doc => stores.push({ ...doc.data(), id: doc.id }));
          setData(stores);
        });
    }
  }, [db, user]);

  const [onOver, setOnOver] = useState(false);

  return (
    <Container>
      {data ? (
        <div
          className="info"
          onMouseOver={() => {
            setOnOver(true);
          }}
          onMouseLeave={() => {
            setOnOver(false);
          }}
        >
          <div className="main-title">
            매장별 정산정보{" "}
            {onOver && (
              <span
                style={{
                  fontSize: "12px",
                  color: "#ff3250",
                  fontWeight: 400
                }}
              >
                선택하여 조회
              </span>
            )}
          </div>
          <OrderSelect data={data} />
        </div>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default Order;
