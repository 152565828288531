import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrow from '../../images/arrow_right.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SelectStore = ({ data }) => {
  return (
    <Container>
      {data.map((item, index) => (
        <StoreItem data={{ ...item, index }} key={index} />
      ))}
    </Container>
  );
};

export default SelectStore;

const Item = styled.div`
  width: 90%;
  padding: 0 5%;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  cursor: pointer;
  color: black;
  font-size: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .image {
    background-image: ${props => `url(${props.img})`};
    background-size: cover;
    background-position: center center;
    width: 40%;
    margin: 0.5rem 0.5rem;
    height: 120px;
  }
`;

const StoreItem = ({ data }) => {
  return (
    <Link to={`/info/store/${data.id}`} style={{ width: '90%', padding: '0 5%' }}>
      <Item img={data.photo}>
        {data.realName} <img src={arrow} alt="" style={{ width: '14px' }} />
      </Item>
    </Link>
  );
};
