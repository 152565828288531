import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { asyncAll } from '../../common/request';
import { sortBy } from 'lodash';
import Loader from '../../components/Loader';
import DatePicker from '../../components/DatePicker';
import HotelCalcItem from './HotelCalcItem';
// import ButtonToSheet from '../../components/ButtonToSheet';

const topInfoStyle = {
  background: '#e6e6e6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  fontSize: '14px',
  color: '#55607d'
};

const HotelCalcList = () => {
  const { user } = useSelector(state => state.user);
  const { db } = useContext(FirebaseContext);
  const [orderData, setOrderData] = useState(null);
  const today = new Date();

  const getCalcDay = () => {
    const date = new Date().getDate();
    if (date < 16) {
      return {
        start: new Date(today.getFullYear(), today.getMonth(), 1),
        end: new Date(today.getFullYear(), today.getMonth(), 15)
      };
    } else {
      return {
        start: new Date(today.getFullYear(), today.getMonth(), 16),
        end: new Date(today.getFullYear(), today.getMonth() + 1, 0)
      };
    }
  };
  const [startDate, setStartDate] = useState(getCalcDay().start);
  const [endDate, setEndDate] = useState(getCalcDay().end);

  useEffect(() => {
    // 1. user의 bn에 속하는 store 목록을 구한다.
    // user에 (미리 넣어놓음) 있는 store 목록을 가져온다.
    let isCancel = false;
    const hotelIds = user && user.group;

    const loadData = id => {
      return new Promise(resolve => {
        db.collection('orders')
          .where('hotel_id', '==', id)
          .where('date', '>=', startDate)
          .where('date', '<=', endDate)
          .where('status', '==', 4)
          .get()
          .then(snapshots => {
            let data = [];
            snapshots.forEach(doc => data.push(doc.data()));
            resolve(data);
          });
      });
    };
    if (hotelIds) {
      const promises = hotelIds.map(id => loadData(id));

      asyncAll(promises).then(result => {
        const flatedArr = result.reduce((acc, val) => acc.concat(val), []);
        !isCancel && setOrderData(sortBy(flatedArr, 'date').reverse());
      });
    }
    return () => (isCancel = true);
  }, [startDate, endDate, user, db]);

  return (
    <>
      {orderData ? (
        <>
          <div style={{ position: 'sticky', top: '47px' }}>
            <div style={topInfoStyle}>
              <DatePicker selectedDate={startDate} onChange={setStartDate} title="시작일을 선택하세요" />
              &nbsp; ~ &nbsp;
              <DatePicker selectedDate={endDate} onChange={setEndDate} title="종료일을 선택하세요" />
              {/* <ButtonToSheet /> */}
            </div>
            <div style={topInfoStyle}>
              <div>
                해당 정산일 기준 총 <b>{orderData.filter(item => !item.calc).length}</b>건
              </div>
            </div>
            <div style={topInfoStyle}>
              문자사용량&nbsp;<b>{orderData.filter(item => item.smsResult === 1).length}</b>건 | 정산예정금액&nbsp;
              <b>{user.commission && user.commission * orderData.filter(item => !item.calc).length}</b>원
            </div>
          </div>
          {orderData.map((item, index) => (
            <HotelCalcItem data={item} key={index} />
          ))}
          <div style={{ height: '47px' }}></div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default HotelCalcList;
