import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import { asyncAll } from '../../common/request';
import { sortBy } from 'lodash';
import CalcItem from './CalcItem';
import { formatPrice, statusToString, getAllTotal } from '../../common/format';
import Loader from '../../components/Loader';
import DatePicker from '../../components/DatePicker';
import xlsx from 'xlsx';
import ButtonToSheet from '../../components/ButtonToSheet';

const Calc = ({ history }) => {
  const { user } = useSelector(state => state.user);
  const { db } = useContext(FirebaseContext);
  const [orderData, setOrderData] = useState(null);
  const today = new Date();

  const getCalcDay = () => {
    const date = new Date().getDate();
    if (date < 16) {
      return {
        start: new Date(today.getFullYear(), today.getMonth(), 1),
        end: new Date(today.getFullYear(), today.getMonth(), 15)
      };
    } else {
      return {
        start: new Date(today.getFullYear(), today.getMonth(), 16),
        end: new Date(today.getFullYear(), today.getMonth() + 1, 0)
      };
    }
  };
  const [startDate, setStartDate] = useState(getCalcDay().start);
  const [endDate, setEndDate] = useState(getCalcDay().end);

  useEffect(() => {
    if (user.businessType === '숙박') {
      history.push('/calc/hotel');
    }
  }, [history, user]);

  useEffect(() => {
    // 1. user의 bn에 속하는 store 목록을 구한다.
    // user에 (미리 넣어놓음) 있는 store 목록을 가져온다.
    let isCancel = false;
    const storeIds = user && user.group;
    if (!storeIds) return setOrderData([]);
    // 불러오는 기준 (진행중인 주문)
    const loadData = id => {
      return new Promise(resolve => {
        db.collection('orders')
          .where('store_id', '==', id)
          .where('date', '>=', startDate)
          .where('date', '<=', endDate)
          .where('status', '==', 4)
          .get()
          .then(snapshots => {
            let data = [];
            snapshots.forEach(doc => data.push(doc.data()));
            resolve(data);
          });
      });
    };
    if (storeIds) {
      const promises = storeIds.map(id => loadData(id));

      asyncAll(promises).then(result => {
        const flatedArr = result.reduce((acc, val) => acc.concat(val), []);
        !isCancel && setOrderData(sortBy(flatedArr, 'date').reverse());
      });
    }
    return () => (isCancel = true);
  }, [startDate, endDate, user, db]);

  const getSum = () => {
    return orderData.reduce((accumulator, item) => accumulator + getAllTotal(item.cart, item.deliveryFee), 0);
  };

  const jsonToSheet = (arr, filename) => {
    const fileName = `${filename}.xlsx`;

    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');

    xlsx.writeFile(wb, fileName);
  };

  return (
    <>
      {orderData ? (
        <>
          <div style={{ position: 'sticky', top: '47px' }}>
            <div
              style={{
                background: '#e6e6e6',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '32px',
                fontSize: '14px',
                color: '#55607d'
              }}
            >
              <DatePicker selectedDate={startDate} onChange={setStartDate} title="시작일을 선택하세요" />
              &nbsp; ~ &nbsp;
              <DatePicker selectedDate={endDate} onChange={setEndDate} title="종료일을 선택하세요" />
              <ButtonToSheet
                onClick={() => {
                  let result = window.confirm('정산 목록을 엑셀 파일로 내려 받으시겠습니까?');
                  if (!result) return;
                  const arr = [];
                  const DELIVERY_FEE = 3000;
                  orderData.forEach(item =>
                    arr.push({
                      [`주문번호`]: item.orderNumber,
                      [`배달주소`]: item.address,
                      [`주문일시`]: item.date && item.date.toDate().toLocaleString(),
                      [`정산여부`]: item.calc ? '정산완료' : '',
                      [`정산금액`]: item.total - DELIVERY_FEE,
                      [`주문상태`]: statusToString(item.status)
                    })
                  );
                  jsonToSheet(arr, `델리퀵_정산내역_${new Date().toLocaleDateString()}`);
                }}
              />
            </div>
            <div
              style={{
                background: '#e6e6e6',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '32px',
                fontSize: '14px',
                color: '#55607d'
              }}
            >
              <div>
                해당 정산일 기준 총 <b>{orderData.length}</b>건 | 정산예정금액 <b>{formatPrice(getSum())}</b>원
              </div>
            </div>
          </div>

          {orderData.map((item, index) => (
            <CalcItem data={item} key={index} />
          ))}
          <div style={{ height: '47px' }}></div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRouter(Calc);
