import React from "react";
import styled from "styled-components";
import ani from "../images/ani.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 70px;
  }
`;

const Loader = () => {
  return (
    <Container>
      <img src={ani} alt="" />
    </Container>
  );
};

export default Loader;
