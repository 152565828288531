import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { asyncAll } from '../../common/request';
import nameIcon from '../../images/name.svg';
import bnIcon from '../../images/bn.svg';
import arrow from '../../images/arrow_right.svg';
import Loader from '../../components/Loader';
import { MdSettings } from 'react-icons/md';
import SelectHotel from './SelectHotel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  .info {
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: white;
    .main-title {
      color: black;
      font-weight: 600;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 5%;
      background: #f9f9f9;
      width: 90%;
    }
    .item {
      border-bottom: 1px solid #e6e6e6;
      width: 90%;
      margin: 0 5%;
      height: 48px;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const InfoForHotel = () => {
  const { db, auth } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.user);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancel = false;
    if (!isCancel && user.group) {
      setIsLoading(true);
      const hotelIds = user.group;
      const promises = hotelIds.map(
        id =>
          new Promise((resolve, reject) => {
            db.collection('hotels')
              .doc(id)
              .get()
              .then(doc => {
                resolve({ ...doc.data(), id });
              })
              .catch(err => reject(new Error(err)));
          })
      );

      asyncAll(promises).then(result => {
        if (!isCancel) {
          result ? setData(result) : setData([]);
          setIsLoading(false);
        }
      });
    }
    return () => (isCancel = true);
  }, [db, user]);

  return (
    <Container>
      {user ? (
        <>
          <div className="info">
            <Link to="/info/edit" className="main-title" style={{ justifyContent: 'space-between' }}>
              <div>내 정보</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdSettings color="#aaa" size="14px" />
                <span style={{ color: '#aaa', fontSize: '12px', marginRight: '4px' }}>설정</span>
                <img src={arrow} alt="" style={{ width: '12px' }} />
              </div>
            </Link>

            <div className="item">
              <img src={nameIcon} alt="" style={{ margin: '0 0.5rem', marginTop: '-3px' }} />
              {user.name}
            </div>
            <div className="item">
              <img src={bnIcon} alt="" style={{ margin: '0 0.5rem' }} />{' '}
              {user.bn.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}
            </div>
          </div>
          <div className="info">
            <div className="main-title">매장 정보</div>
            {!isLoading && data ? (
              <SelectHotel data={data} />
            ) : (
              <div
                style={{
                  width: '100%',
                  color: '#aaa',
                  fontSize: '12px',
                  textAlign: 'center'
                }}
              >
                loading...
              </div>
            )}
            {!isLoading && data && data.length === 0 && (
              <div
                style={{
                  width: '100%',
                  fontSize: '12px',
                  color: '#aaa',
                  textAlign: 'center',
                  padding: '1rem 0'
                }}
              >
                아직 등록된 매장이 없습니다.
              </div>
            )}
          </div>

          <div className="info">
            {user && (
              <div
                style={{
                  color: '#aaaaaa',
                  fontSize: '14px',
                  justifyContent: 'center'
                }}
                className="main-title"
                onClick={() => {
                  auth.signOut();
                }}
              >
                로그아웃
              </div>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default InfoForHotel;
