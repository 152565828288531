import React from "react";

const Terms2 = () => {
  return (
    <div style={{ fontSize: "12px" }}>
      <h1>판매자 개인정보 처리방침</h1>

      <p>
        <strong>
          퍼블릭스 주식회사(이하 &#39;회사&#39;이라고 함)는 최소한의 정보만을
          필요한 시점에 수집하며, 수집하는 정보는 고지한 범위 내에서만 사용되며,
          사전 동의 없이 그 범위를 초과하여 이용하거나 외부에 공개하지 않습니다.
        </strong>
      </p>

      <p>
        수집된 정보에 대하여 여러분은 충분한 권리를 행사할 수 있습니다. 또한,
        개인정보 처리방침이 변경되었을 때는 여러분께 항상 알리며, 수시로 확인할
        수 있도록 게시하겠습니다. 본 개인정보 처리방침은 회사의 처리하는
        판매자(단순 business 상담자를 포함, 이하 &#39;이용자&#39;라고 함)의
        개인정보를 처리함에 있어 법률에 근거한 회사의 정책을 반영하고 있으며
        구체적으로 다음과 같은 내용을 담고 있습니다.
      </p>

      <p>회사는 아래와 같이 개인정보를 보호하고 있습니다.</p>

      <ol>
        <li>개인정보 수집·이용, 보유 및 이용기간</li>

        <li>개인정보의 제3자 제공</li>

        <li>개인정보의 처리위탁</li>

        <li>개인정보의 파기절차 및 방법</li>

        <li>이용자의 권리와 그 행사 방법</li>

        <li>
          이용자의 권리와 그 행사 방법 이용자의 권리와 그 행사 방법 이용자의
          권리와 그 행사 방법
        </li>

        <li>개인정보 안전성 확보 조치</li>

        <li>개인정보 보호책임자 및 담당자의 연락처</li>

        <li>고지의 의무</li>
      </ol>

      <h2 id="1">
        <strong>
          1. 회사는 이용하는 서비스의 형태에 따라 다음과 같이 개인정보를 수집 및
          이용하고, 그 보유 및 이용기간은 아래와 같습니다.
        </strong>
      </h2>

      <br />
      <table>
        <thead>
          <tr>
            <th>구분</th>
            <th>수집·이용목적</th>
            <th>수집·이용항목</th>
            <th>보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="3">필수</td>
            <td>
              입점 신청/문의 및 상담, 입점 업체 관리, 서비스 이용, 부정이용확인
              및 방지 Open API이용을 위한 Key 발급 제휴를 위한 의사소통 경로의
              확보, 고지사항 전달, 서비스 이용에 따르는 민원사항 처리, 중복가입
              확인, 부정 이용 방지 새로운 서비스 및 이벤트 정보 등 최신 정보
              안내, 인구통계학적 분석
            </td>
            <td>
              [물품 판매자] 아이디, 비밀번호, 업체정보(대표자명, 대표번호,
              대표이메일, 사업장주소), 사업자번호, 사업의 종류(업태, 업종)
              담당자정보(담당자명, 전화번호, 휴대폰번호, 이메일) [델리퀵 판매자]
              아이디, 비밀번호, 업체정보(대표자명, 매장연락처, 매장주소),
              사업자번호, 생년월일(개인사업자만 해당)
            </td>
            <td rowSpan="3">
              이용계약 종료시까지. 단, 관계법령에 따라 또는 회사 정책에 따른
              정보보유사유가 발생하여 보존할 필요가 있는 경우에는 필요한 기간
              동안 해당 판매자 정보를 보관합니다.
            </td>
          </tr>
          <tr>
            <td>본인 확인 및 부정이용 방지</td>
            <td>본인확인정보(CI, DI)</td>
          </tr>
          <tr>
            <td>세금계산서 발행, 대금결제서비스 제공</td>
            <td>정산정보(계좌정보, 예금주명, 통장사본)</td>
          </tr>
          <tr>
            <td>선택</td>
            <td>서비스 이용</td>
            <td>팩스번호</td>
            <td>이용계약 종료시까지</td>
          </tr>
        </tbody>
      </table>

      <ul>
        <li>서비스 특성에 따라 추가 구비서류가 요청될 수 있습니다.</li>

        <li>
          회사의 서비스를 통하여 물품을 매매(등록, 판매를 포함)한 내역이 있는
          판매자에 대하여 이용계약이 종료된 경우, 거래당사자들에 대한 정보제공과
          이 약관에 정한 제한을 의도적으로 회피하기 위한 임의 해지를 방지하기
          위하여 이용계약종료 후 1년간 아이디(ID), 성명 또는 상호, 연락처,
          배송관련주소, 대금송금계좌정보 등 최소한의 필요정보를 보관합니다.
        </li>

        <li>
          회사의 서비스 이용계약을 해지하거나 회사의 서비스 이용 재계약에 대한
          승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 계약종료 후
          5년간 아이디(ID), 성명 또는 상호 등의 최소한의 필요정보를 보관합니다.
        </li>
      </ul>

      <h2 id="23">
        <strong>
          2. 서비스의 제공 또는 법령에 정해진 책임의 준수를 위해 보관하고 있는
          개인정보가 제3자에게 수집 목적 이내에서 제공될 수 있습니다.
        </strong>
      </h2>

      <p>
        회사는 수집한 개인정보를 귀하에게 동의 받은 목적 범위 내에서 사용하며,
        동의 없는 범위를 초과하여 이용하거나 외부에 공개·제공하지 않습니다.
        다만, 아래의 경우에는 예외로 합니다.
      </p>

      <ol>
        <li>개인정보의 주체가 공개 및 제공 등에 동의한 경우</li>

        <li>
          기타, 법령에서 요구되는 경우 선의로 판단되는 경우 (예. 관련법령에 의거
          적법한 절차에 의한 조사기관 및 수사기관 등의 요청이 있는 경우 등)
        </li>
      </ol>

      <h2 id="3">
        <strong>
          3. 회사는 원활한 서비스 제공과 효과적인 업무처리를 위하여 다음과 같이
          개인정보를 처리 위탁하고 있습니다.
        </strong>
      </h2>

      <p>
        회사는 귀하께서 제공하시는 개인정보를 서비스 향상을 위하여 전문업체에
        위탁하고 있습니다.
      </p>

      <p>① 개인정보 국내 처리위탁 현황</p>

      <br />
      <table>
        <thead>
          <tr>
            <th>수탁사</th>
            <th>위탁업무</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Google LLC.</td>
            <td>데이터 보관</td>
          </tr>
          <tr>
            <td>Microsoft</td>
            <td>이메일, 문서/파일 저장과 내부 커뮤니케이션 및 협업 도구</td>
          </tr>
        </tbody>
      </table>

      <p>※ 개인정보 보호책임자 연락처는 02-577-0420입니다.</p>

      <h2 id="4">
        <strong>
          4. 원칙적으로 회사는 수집한 개인정보에 대해 보관해야 되는 목적이
          달성된 후, 즉시 해당 개인정보를 파기하고 있습니다. 개인정보의 파기
          절차, 기한 및 방법은 다음과 같습니다.
        </strong>
      </h2>

      <p>
        ① 파기절차, 기한 수집•이용목적이 달성된 개인정보의 경우 별도의 DB에
        옮겨져 내부규정 및 관련 법령을 준수하여 안전하게 보관되며, 정해진 기간이
        종료되었을 때, 지체 없이 파기됩니다. 이 때, 별도의 DB로 옮겨진
        개인정보는 여러분 각자가 동의한 목적을 초과하거나 법률에 정한 경우가
        아니고서는 다른 목적으로 이용되지 않습니다.
      </p>

      <p>② 파기방법</p>

      <ul>
        <li>
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다.
        </li>

        <li>
          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </li>
      </ul>

      <p>
        ③ 다만, 다른 법령에 따라 개인정보 보유·이용기간이 다르게 정해져 있는
        경우에는 별도로 처리될 수 있습니다.
      </p>

      <p>[관계법령에 따른 경우]</p>

      <br />
      <table>
        <thead>
          <tr>
            <th>보유정보</th>
            <th>보유기간</th>
            <th>근거법령</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>계약 또는 청약철회 등에 관한 기록</td>
            <td>5년</td>
            <td rowSpan="3">전자상거래 등에서의 소비자보호에 관한 법률</td>
          </tr>
          <tr>
            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>표시•광고에 관한 기록</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>웹사이트 방문 기록</td>
            <td>3개월</td>
            <td>통신비밀보호법</td>
          </tr>
        </tbody>
      </table>

      <p>[회사 정책에 따른 정보보유 사유가 발생한 경우]</p>

      <ul>
        <li>
          회사의 서비스를 통하여 물품을 매매(등록, 판매를 포함)한 내역이 있는
          판매자에 대하여 이용계약이 종료된 경우, 거래당사자들에 대한 정보제공과
          이 약관에 정한 제한을 의도적으로 회피하기 위한 임의해지를 방지하기
          위하여 이용계약종료 후 1년간 아이디(ID), 성명 또는 상호, 연락처,
          배송관련주소, 대금송금계좌정보 등 최소한의 필요정보를 보관합니다.
        </li>

        <li>
          회사의 서비스 이용계약을 해지하거나 회사의 서비스 이용 재계약에 대한
          승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 계약종료 후
          5년간 아이디(ID), 성명 또는 상호 등의 최소한의 필요정보를 보관합니다.
        </li>
      </ul>

      <h2 id="5">
        <strong>
          5. 회사의 서비스를 이용하는 여러분들은 정보주체로서 다음과 같은 권리를
          행사할 수 있습니다.
        </strong>
      </h2>

      <p>
        귀하는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
        있으며 정보삭제 및 처리정지를 요청할 수도 있습니다. 조회, 정보삭제 또는
        처리정지를 원하시는 경우 개인정보보호 담당자에게 전화로 연락하시면 지체
        없이 조치하겠습니다.
      </p>

      <p>
        개인정보의 처리(조회, 정보삭제 또는 처리정지)를 요구할 수 있는 자는 본
        방침에 따라 처리하고 있는 개인정보의 정보주체 본인에 한정하며, 속이는
        행위로 타인의 개인정보 및 사생활을 침해하거나 시도하려는 자는
        민·형사상의 처벌을 받을 수 있습니다.
      </p>

      <h2 id="6cookie">
        <strong>
          6. 쿠키(Cookie)의 설치, 운영 및 그 거부에 관한 사항을 알려드립니다.
        </strong>
      </h2>

      <p>
        ① 쿠키(Cookie)의 정의 쿠키(Cookie)는 웹사이트를 운영하는데 이용되는
        서버가 이용자의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 이용자의
        컴퓨터 하드디스크에 저장되고 있습니다. 따라서 여러분 스스로가 쿠키의
        설치 및 수집에 대해 자율적으로 선택할 수 있으므로 수집을 거부할 수
        있습니다. 다만, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의
        이용에 제한이 생길 수 있습니다.
      </p>

      <p>
        ② 쿠키 설정 거부 방법 1) Internet Explorer의 경우 웹 브라우저 상단의
        &quot;도구&quot; 메뉴 \&gt; &quot;인터넷 옵션&quot; 메뉴 \&gt;
        &quot;개인정보&quot; 탭 \&gt; 직접 설정 2) Chrome의 경우 웹 브라우저
        우측 상단의 아이콘 &quot;&quot; 선택 \&gt; &quot;설정&quot;선택 \&gt;
        화면 하단의 &quot;고급 설정 표시&quot; 선택 \&gt;개인정보 섹션의
        &quot;콘텐츠 설정&quot; 버튼 \&gt; 쿠키 섹션에서 직접 설정
      </p>

      <h2 id="7">
        <strong>7. 개인정보 안전성 확보 조치</strong>
      </h2>

      <p>
        회사는 여러분의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출,
        변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적,
        관리적 조치를 강구하고 있습니다.
      </p>

      <p>
        ① 내부관리계획의 수립 및 시행 회사는 개인정보의 안전한 처리를 위하여
        내부 정책을 수집하여 운영하고 있으며, 정보보안 감사 등을 통해 개인정보
        보호조치의 이행사항을 확인하여 문제점 발견 시 즉시 개선을 하도록
        조치하고 있습니다.
      </p>

      <p>
        ② 접근통제장치의 설치 및 운영 회사는 침입차단시스템 등 보안솔루션을
        이용하여 외부로부터 무단 접근을 통제하고 있으며, 기타 시스템적으로
        보안성을 확보하기 위하여 가능한 모든 기술적 장치를 갖추려 노력하고
        있습니다.
      </p>

      <p>
        ③ 접속기록의 위조, 변조 방지를 위한 조치 회사는 개인정보처리시스템에
        접속한 기록을 보관, 관리하고 있으며, 접속기록이 위조, 변조되지 않도록
        관리하고 있습니다.
      </p>

      <p>
        ④ 개인정보의 암호화 회사는 여러분의 개인정보를 암호화된 통신구간을
        이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
      </p>

      <p>
        ⑤ 해킹 등에 대비한 대책 회사는 해커 등의 침입을 탐지하고 차단할 수 있는
        시스템을 통하여 24시간 감시하고 있으며, 백신프로그램을 이용하여
        컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.
        백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우
        백신이 나오는 즉시 이를 제공함으로써 개인 정보가 침해되는 것을 방지하고
        있습니다. 또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에
        적용하고 있습니다.
      </p>

      <p>
        ⑥ 취급 직원의 최소화 및 교육 회사는 이용자의 개인정보에 대한 접근권한을
        업무상 개인정보의 취급이 불가피한 자로 제한하고 있습니다. 개인정보를
        처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한
        정기적인 교육과 캠페인을 실시하고 있습니다. 또한, 입사시 전 직원의
        보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고
        개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감사하기 위한
        내부절차를 마련하고 있습니다. 개인정보 관련 취급자의 업무 인수인계는
        보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보
        사고에 대한 책임을 명확히 하고 있습니다.
      </p>

      <h2 id="8">
        <strong>
          8. 회사는 귀하의 안전한 개인정보 보호를 위해 개인정보 보호책임자를
          지정하고 있습니다.
        </strong>
      </h2>

      <p>
        ① 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
        이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
        보호책임자를 지정하고 있습니다.
      </p>

      <p>개인정보 보호책임자/ 민원처리 담당 성명: 정성진 연락처: 02-577-0420</p>

      <p>
        ② 회사가 제공하는 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보
        보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 관리책임자
        및 고객지원센터로 문의하실 수 있으며, 회사는 이런 문의에 대해 지체 없이
        답변 및 처리 할 것입니다
      </p>

      <p>
        ③ 개인정보가 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
        바랍니다. ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영): (국번없이)
        118 / privacy.kisa.or.kr ▶ 정보보호마크 인증 위원회: 02-550-9531~2 /{" "}
        <a href="http://eprivacy.or.kr/">privacy.kisa.or.kr</a>▶ 대검찰청
        사이버범죄수사단: (국번없이) 1301 /{" "}
        <a href="http://www.spo.go.kr/">www.spo.go.kr</a>▶ 경찰청 사이버안전국:
        182 / <a href="http://cyber.go.kr/">www.cyber.go.kr</a>
      </p>

      <h2 id="9">
        <strong>
          9. 개인정보 처리방침 변경에 관한 정책은 다음과 같습니다.
        </strong>
      </h2>

      <p>
        이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
        변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터
        고지할 것입니다. 그러나 개인정보의 수집 및 활용, 제3자 제공 등과 같이
        이용자의 권리에 중요한 변경의 사유가 발생하는 경우 최소 14일 전에
        공지합니다. 또한 관련 법령이나 회사정책의 변경으로 불가피하게 방침을
        변경해야 하는 경우, 웹사이트를 통해 빠르게 알려드리고 있으니 참고하여
        주시기 바랍니다.
      </p>

      <p>개인정보처리방침 버전 번호: 5.0</p>

      <p>
        현재 개인정보처리방침 공고일자: 2019년 10월 05 일 현재 개인정보처리방침
        시행일자: 2019년 10월 05 일
      </p>
    </div>
  );
};

export default Terms2;
