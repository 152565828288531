import { formatDistance } from 'date-fns';
import { ko } from 'date-fns/locale';

export const formatPrice = number => {
  return Number(number).toLocaleString('en');
};

export const _capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPhone = number => {
  if (number) {
    if (number.length === 12) {
      return number.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (number.length === 11) {
      return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (number.length === 10) {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (number.length === 9) {
      return number.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
      return number;
    }
  }
};

export const dateToWords = date => {
  const time = new Date() - date;
  if (time > 3600000) {
    return `${date.getMonth() + 1}. ${date.getDate()}. ${date.toLocaleTimeString()}`;
  } else {
    return (
      formatDistance(date, new Date(), {
        includeSeconds: true,
        locale: ko
      }) + ' 전'
    );
  }
};

export const statusToString = status => {
  switch (status) {
    case 1:
      return '접수대기';
    case 2:
      return '접수완료';
    case 3:
      return '배달중';
    case 4:
      return '배달완료';
    case -1:
      return '주문취소';
    default:
      return '';
  }
};

export const statusToColor = status => {
  switch (status) {
    case 1:
      return {
        background: '#ff3250',
        color: '#fff'
      };
    case 2:
      return {
        background: '#55607d',
        color: '#fff'
      };
    case 3:
      return {
        background: '#55607d',
        color: '#fff'
      };
    case 4:
      return {
        background: '#e6e6e6',
        color: '#55607d'
      };
    case -1:
      return {
        background: '#aaaaaa',
        color: '#fff'
      };
    default:
      return {
        background: '#55607d',
        color: '#fff'
      };
  }
};

export const getEachTotal = cart => {
  if (!cart) return null;
  let optionPrice = 0;
  cart.options.map(option => (optionPrice += parseInt(option.price)));
  let totalPrice = 0;
  totalPrice += optionPrice + parseInt(cart.price);
  return totalPrice;
};

export const getAllTotal = (carts, deliveryFee = 0) => {
  if (!carts) return null;
  let totalPrice = 0;
  carts.forEach(item => {
    totalPrice += getEachTotal(item);
  });
  return totalPrice + parseInt(deliveryFee);
};

export const checkBn = value => {
  if (!value) return false;
  const valueMap = value
    .replace(/-/gi, '')
    .split('')
    .map(function(item) {
      return parseInt(item, 10);
    });

  if (valueMap.length === 10) {
    const keys = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let checkSum = 0;

    for (let i = 0; i < keys.length; i++) {
      checkSum += keys[i] * valueMap[i];
    }

    checkSum += parseInt((keys[8] * valueMap[8]) / 10, 10);
    // return Math.floor(valueMap[9]) === 10 - (checkSum % 10);
    return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10;
  }

  return false;
};
