import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import LoaderDot from './Loaders/LoaderDot';
const authKey = process.env.REACT_APP_FUNCTIONS_AUTH_KEY;

const StyledButton = styled.button`
  border: none;
  outline: none;
  color: #fff;
  background: ${({ bgColor }) => bgColor};
  border-radius: 16px;
  font-weight: bold;
  font-size: 14px;
  padding: 0.3rem 0.5rem;
  min-width: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

function SendSms({ updateResult, defaultStatus = 0, data, style }) {
  const [status] = useState([
    { msg: '배달도착알림', color: '#ff3250' },
    { msg: '전송성공', color: '#12b886' },
    { msg: '전송실패', color: '#868e96' }
  ]);
  const [current, setCurrent] = useState(defaultStatus);
  const [isLoading, setIsLoading] = useState(false);

  const test = async () => {
    if (current !== 0) return;
    setIsLoading(true);
    // http://localhost:5000/deliquick-93b6b/us-central1/api
    const result = await axios({
      url: 'https://us-central1-deliquick-93b6b.cloudfunctions.net/api/order/sendSms',
      method: 'POST',
      headers: {
        Authorization: authKey
      },
      data: {
        from: 'deliquick',
        to: data.countryCode + data.phoneNo,
        language: data.language
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });

    if (result.data.status === 'SUCCESS') {
      setCurrent(1);
      updateResult(1);
    } else {
      setCurrent(2);
      updateResult(2);
    }
    setIsLoading(false);
  };

  return (
    <StyledButton
      onClick={() => {
        test();
      }}
      bgColor={status[current].color}
      style={style}
    >
      {isLoading ? (
        <>
          전송중
          <LoaderDot />
        </>
      ) : (
        status[current].msg
      )}
    </StyledButton>
  );
}

export default SendSms;
