import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import { withRouter } from 'react-router-dom';
import { formatPrice } from '../../common/format';
import ToggleButton from '../../components/ToggleButton';
import { success } from '../../components/Toast';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  .item {
    display: flex;
    min-height: 48px;
    align-items: center;
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid #e6e6e6;
  }
  .title {
    background: #f9f9f9;
    font-weight: bold;
    display: flex;
    height: 48px;
    align-items: center;
    width: 90%;
    padding: 0 5%;
  }
`;

const EditStoreInfo = props => {
  const { db } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const [data, setData] = useState(null);

  const storeRef = db.collection('stores').doc(id);

  useEffect(() => {
    storeRef
      .get()
      .then(doc => setData(doc.data()))
      .catch(err => console.log(err));
  }, [db]); // eslint-disable-line

  const changeStatus = active => {
    setData({ ...data, active });
    return storeRef.update({ active }).then(() => {
      if (active) {
        success(
          <>
            활성화 되었습니다. <br />
            (다음 마감시간에 자동 전환 됩니다.)
          </>
        );
      } else {
        success(
          <>
            비활성화 되었습니다. <br />
            (다음 오픈시간에 자동 전환 됩니다.)
          </>
        );
      }
    });
  };

  return (
    <>
      <Container>
        {data && (
          <>
            <div className="title">{data.realName}</div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>전화번호</b>
              {data.phone}
            </div>
            <div className="item">
              <b style={{ whiteSpace: 'nowrap', marginRight: '6px' }}>주소</b>
              <div style={{ fontSize: '14px' }}>
                <div>{data.address.jibun}</div>
                <div style={{ color: '#aaa', fontSize: '10px' }}>{data.address.road}</div>
              </div>
            </div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>최소주문금액</b>
              {formatPrice(data.minprice)}원
            </div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>영업시간</b>
              {data.time}
            </div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>휴무일</b>
              {data.minprice}
            </div>
            <div className="item" style={{ justifyContent: 'space-between' }}>
              <b style={{ marginRight: '6px' }}>영업설정</b>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#aaa', fontSize: '14px', marginRight: '0.5rem' }}>
                  {data.active ? '영업중' : '영업종료'}
                </span>
                <ToggleButton
                  style={{ marginRight: '6px', marginTop: '6px' }}
                  defaultChecked={data.active}
                  onChange={e => {
                    changeStatus(e.target.checked);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default withRouter(EditStoreInfo);
