import React, { useRef, useState } from 'react';
import DatePick from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { useOnClickOutside } from '../common/hooks';
import { format } from 'date-fns';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DatePicker = ({ selectedDate, onChange, title }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <>
      <div
        style={{
          border: '1px solid #aaa',
          background: '#fff',
          borderRadius: '4px',
          padding: '0.2rem 0.4rem'
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {format(selectedDate, 'yy/MM/dd')}
      </div>
      {open && (
        <Container>
          <span
            ref={ref}
            style={{
              padding: '1rem',
              background: 'white',
              textAlign: 'center',
              borderRadius: '4px'
            }}
          >
            <div
              style={{
                padding: '0.4rem 0',
                textAlignLast: 'center',
                zIndex: 1,
                width: '100%'
              }}
            >
              <b style={{ marginLeft: '26px' }}> {title}</b>
            </div>

            <DatePick
              selected={selectedDate}
              dateFormat="yyyy/MM/dd"
              onChange={date => {
                onChange(date);
                setOpen(false);
              }}
              locale={ko}
              inline
            />
          </span>
        </Container>
      )}
    </>
  );
};

export default DatePicker;
