import React from "react";
import ani from "../images/ani.png";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  return (
    <Container>
      <img src={ani} alt="" style={{ width: "100px" }} />
    </Container>
  );
};

export default Loader;
