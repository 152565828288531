import React from 'react';
import styled from 'styled-components';
import { dateToWords, statusToColor } from '../../common/format';
import foodIcon from '../../images/food.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  .order-bold {
    font-size: 12px;
    color: #aaaaaa;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    height: 48px;
    width: 90%;
    margin: 0 5%;
  }
  .between {
    justify-content: space-between;
  }
  .border-top {
    padding: 0.5rem 0;
    border-top: 1px solid #dfdfdf;
  }
`;
const Status = styled.div`
  padding: 0.8rem 0;
  text-align: center;
  background: ${props => props.background};
  width: 100%;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;
`;

const HotelCalcItem = ({ data }) => {
  return (
    <Container>
      <div className="flex-row between" style={{ height: '32px' }}>
        <div className="order-bold">#{data.orderNumber}</div>
        <div style={{ fontSize: '12px', color: '#aaa' }}>{dateToWords(data.date.toDate())}</div>
      </div>
      <div
        className="flex-row"
        style={{
          height: 'fit-content',
          alignItems: 'center',
          padding: '12px 0',
          borderBottom: 'none'
        }}
      >
        <img src={foodIcon} alt="" />
        <div>
          [메뉴 {data.cart.length}개]
          {data.cart[0].realName} {data.cart.length > 1 && `외 ${data.cart.length - 1}개`}
        </div>
      </div>
      {/* {data.smsResult === 1 && (
        <div className="flex-row" style={{ border: 'none' }}>
          <span>문자전송</span>&nbsp;&nbsp;
          <span>{data.smsResult}</span>
        </div>
      )} */}
      <Status background={statusToColor(data.status).background} color={statusToColor(data.status).color}>
        {data.calc ? '정산완료' : '정산예정'}
      </Status>
    </Container>
  );
};

export default HotelCalcItem;
