import axios from 'axios';
import { formatPhone } from './format';

export const onSubmitByVroong = async (info, store_user, pickuptime, notes) => {
  // orderNo로 변경 requestId
  const data = {
    request_id: `publics_${info.orderNumber}`,
    branch_code: store_user.vroong_id,
    sender_phone: formatPhone(store_user.phone),
    dest_address: info.hotelData.address.jibun,
    dest_address_detail: info.hotelData.address.detail || '',
    dest_address_road: info.hotelData.address.road || '',
    dest_address_detail_road: info.hotelData.address.detail || '',
    dest_lat: info.hotelData.address.extras.y,
    dest_lng: info.hotelData.address.extras.x,
    payment_method: 'PREPAID',
    delivery_value: 0,
    pickup_in: pickuptime, // 픽업 시간 단위 초
    recipient_phone: formatPhone(info.hotelData.phone),
    order_notes: notes || ''
  };
  return await new Promise(resolve => {
    axios({
      url: 'https://us-central1-deliquick-93b6b.cloudfunctions.net/api/vroong/submit',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then(result => {
      resolve(result.data);
    });
  });
};

export const trackVroong = async (delivery_id, status, orderNumber) => {
  return await new Promise(resolve => {
    axios({
      url: 'https://us-central1-deliquick-93b6b.cloudfunctions.net/api/vroong/track',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        delivery_id,
        status,
        orderNumber
      }
    }).then(result => {
      resolve(result.data);
    });
  });
};

export const asyncAll = async promises => {
  return await Promise.all(promises);
};
