import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  background: #e6e6e6;
  color: #666;
  line-height: 1;
  width: 100%;
  font-size: 10px;
  text-align: center;
  margin-top: 24px;
  padding-bottom: 1rem;
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.33;
    padding: 1rem 0 0.5rem 0;
  }
`;

const Footer = () => {
  return (
    <Container>
      <div className="footer-top">
        <div>델리퀵 사장님페이지 | 퍼블릭스(주) </div>
      </div>
      <div style={{ lineHeight: 1.33 }}>
        <b>이메일: help@publics.co.kr | 전화: 02-577-0420</b>
        <br />
        JS 타워, 79, 6 테헤란로 삼성동 강남구 서울특별시 <br />
        All Contents Copyright © Publics Inc.
      </div>
    </Container>
  );
};

export default Footer;
