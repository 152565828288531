import React, { useState, useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../firebase';
import { withRouter } from 'react-router-dom';
import loginImage from '../images/login.svg';
import Spinner from '../components/Spinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  text-align: left;
  border: none;
  padding: 1rem;
  font-size: 16px;
  max-width: 400px;
  width: 80%;
  border-radius: 24px;
  background: #f8f8f8;
  color: #55607d;
  font-weight: bold;
  line-height: 1.13;
  margin-top: 8px;
  &::placeholder {
    color: #aaa;
    font-weight: normal;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px 0px #ff3250;
  }
`;

const Button = styled.div`
  margin-top: 8px;
  padding: 0.8rem;
  border-radius: 24px;
  background: ${props => (props.active ? '#ff3250' : '#aaa')};
  color: white;
  font-size: 18px;
  max-width: 400px;
  width: 80%;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  :active {
    opacity: 0.7;
  }
`;

const Login = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const { auth } = useContext(FirebaseContext);
  const [error, setError] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged(result => !result && setIsLoading(false));
  }, [auth]);

  const active = useMemo(() => {
    if (email && pw) {
      return true;
    } else {
      return false;
    }
  }, [email, pw]);

  const onLogin = (email, pw) => {
    setIsLoading(true);
    setError('');

    auth
      .signInWithEmailAndPassword(email, pw)
      .then(result => {
        if (result.user) {
          // const { uid } = result.user;
        }
      })
      .catch(err => {
        console.log(err);
        if (err.code === 'auth/wrong-password') {
          setError('비밀번호가 일치하지 않습니다.');
        } else if (err.code === 'auth/invalid-email') {
          setError('이메일주소가 유효하지 않습니다.');
        }
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <img
        src={loginImage}
        alt=""
        style={{ width: '65%', maxWidth: '380px', marginTop: '-1.5rem', marginBottom: '-1.5rem' }}
      />
      <div
        style={{
          marginBottom: '0.5rem',
          fontSize: '20px',
          fontWeight: 600,
          color: '#373751'
        }}
      >
        델리퀵 파트너
      </div>
      <Input placeholder=" 이메일 주소" onChange={e => setEmail(e.target.value)} value={email} />
      <Input
        placeholder=" 비밀번호"
        type="password"
        onKeyDown={e => {
          if (e.keyCode === 13 && active) {
            onLogin(email, pw);
          }
        }}
        onChange={e => {
          setPw(e.target.value);
        }}
      />
      <div
        style={{
          padding: '0.2rem',
          color: '#fa5252',
          fontSize: '12px',
          marginBottom: '0.2rem'
        }}
      >
        {error}
      </div>
      <Button
        active={active}
        style={{ marginTop: '0.5rem' }}
        onClick={() => {
          if (active) {
            onLogin(email, pw);
          }
        }}
      >
        {isLoading ? <Spinner /> : '로그인'}
      </Button>
      <Button
        style={{ background: '#55607d' }}
        onClick={() => {
          props.history.push('/join/1');
        }}
      >
        회원가입
      </Button>
    </Container>
  );
};

export default withRouter(Login);
