import cogo from 'cogo-toast';

const toastConfig = {
  position: 'top-right',
  hideAfter: 2,
  heading: ''
};

export const success = msg => {
  return cogo.success(msg, toastConfig);
};

export const alert = msg => {
  return cogo.warn(msg, toastConfig);
};

export const loading = msg => {
  return cogo.loading(msg, toastConfig);
};
