import React, { useContext, useEffect, useState, useMemo } from 'react';
import { FirebaseContext } from '../../firebase';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { formatPrice } from '../../common/format';
import styled from 'styled-components';
import Loader from '../../Icons/Loader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
const Details = styled.table`
  padding: 0.5rem;
  border: 0.5px solid #ddd;
  margin-bottom: 0.2rem;
  text-align: center;
  width: 50%;
  min-width: 200px;
  th {
    color: #000;
    font-weight: 400;
  }
  td {
    color: gray;
  }
`;

const OrderList = ({ match }) => {
  const { id } = match.params;
  const { db } = useContext(FirebaseContext);

  const [data, setData] = useState();

  const calcEstimate = useMemo(() => {
    let result = 0;
    const estimated = data && data.filter(item => item.calc === '정산예정');
    estimated && estimated.map(item => (result += item.total));
    return { count: estimated && estimated.length, total: result };
  }, [data]);

  const calcComplete = useMemo(() => {
    let result = 0;
    const estimated = data && data.filter(item => item.calc === '정산완료');
    estimated && estimated.map(item => (result += item.total));
    return { count: estimated && estimated.length, total: result };
  }, [data]);

  const columns = [
    {
      id: 'date',
      Header: '주문일시',
      accessor: data => data.date.toDate().toLocaleString()
    },
    {
      Header: '주문번호',
      accessor: 'orderNumber'
    },
    {
      Header: '배달주소',
      accessor: 'address'
    },
    {
      id: 'total',
      Header: '총합',
      accessor: data => `${formatPrice(data.total)}원`
    },
    {
      Header: '주문상태',
      accessor: 'status',
      Cell: row => <span>{row.value}</span>
    },
    {
      Header: '정산현황',
      accessor: 'calc',
      Cell: row =>
        row.value === '주문취소' ? (
          <span style={{ color: '#fa5252' }}>{row.value}</span>
        ) : row.value === '정산예정' ? (
          <span style={{ color: '#fd7e14' }}>{row.value}</span>
        ) : row.value === '정산완료' ? (
          <span style={{ color: '#40c057' }}>{row.value}</span>
        ) : (
          <span>{row.value}</span>
        )
    }
  ];

  useEffect(() => {
    db.collection('orders')
      .where('store_id', '==', id)
      .get()
      .then(snapshots => {
        const orders = [];
        snapshots.forEach(doc => orders.push({ ...doc.data(), id: doc.id }));
        setData(orders);
      });
  }, [id, db]);

  return (
    <Container>
      <div style={{ width: '90%', fontSize: '14px', marginBottom: '1rem' }}>
        * 매달 정산일은 15일 입니다.
      </div>
      {data ? (
        <>
          <ReactTable
            style={{ fontSize: '13px', width: '90%' }}
            data={data}
            columns={columns}
            defaultSorted={[
              {
                id: 'date',
                desc: true
              }
            ]}
            SubComponent={sub => (
              <div style={{ padding: '12px 12px 12px 2rem' }}>
                <div
                  style={{
                    marginBottom: '0.3rem'
                  }}
                >
                  상세 주문 내역
                </div>
                {sub.original.cart.map(item => (
                  <Details>
                    <tr>
                      <th>{item.name}</th>
                      <th>{`${formatPrice(item.price)}원`}</th>
                    </tr>
                    {item.options &&
                      item.options.map(option => (
                        <tr>
                          <td>{option.name}</td>
                          <td>+{formatPrice(option.price)}</td>
                        </tr>
                      ))}
                    <tr style={{ background: '#efefef' }}>
                      <th>합계</th>
                      <th>{`${formatPrice(item.total)}원`}</th>
                    </tr>
                  </Details>
                ))}
              </div>
            )}
            defaultPageSize={10}
            className="-striped -highlight"
            previousText="이전"
            nextText="다음"
            pageText="현재 페이지"
            ofText="/"
            rowsText="열"
          />
          <div
            style={{
              width: '90%',
              border: '1px solid #e8e8e8',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              fontSize: '14px'
            }}
          >
            <div style={{ display: 'flex', padding: '0.2rem 0.5rem' }}>
              <div style={{ width: '150px', textAlign: 'right' }}>정산 예정 건수</div>
              <div style={{ width: '100px', textAlign: 'right' }}>{calcEstimate.count}건</div>
            </div>
            <div style={{ display: 'flex', padding: '0.2rem 0.5rem' }}>
              <div style={{ width: '150px', textAlign: 'right' }}>정산 예정 금액</div>
              <div style={{ width: '100px', textAlign: 'right' }}>
                {formatPrice(calcEstimate.total)}원
              </div>
            </div>
          </div>
          <div
            style={{
              width: '90%',
              border: '1px solid #e8e8e8',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              fontSize: '14px',
              borderTop: 'none'
            }}
          >
            <div style={{ display: 'flex', padding: '0.2rem 0.5rem' }}>
              <div style={{ width: '150px', textAlign: 'right' }}>총 정산 건수</div>
              <div style={{ width: '100px', textAlign: 'right' }}>{calcComplete.count}건</div>
            </div>
            <div style={{ display: 'flex', padding: '0.2rem 0.5rem' }}>
              <div style={{ width: '150px', textAlign: 'right' }}>총 정산 누적 금액</div>
              <div style={{ width: '100px', textAlign: 'right' }}>
                {formatPrice(calcComplete.total)}원
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default withRouter(OrderList);
