import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { asyncAll } from '../../common/request';
import { sortBy } from 'lodash';
import Loader from '../../components/Loader';
import OrderItem from './OrderItem';

const HotelOrders = () => {
  const { user } = useSelector(state => state.user);
  const { db } = useContext(FirebaseContext);
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState([]);

  useEffect(() => {
    const hotelIds = user && user.group;
    const today = new Date();
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    let isCancel = false;

    // 불러오는 기준 (진행중인 주문)
    const loadData = id => {
      return new Promise(resolve => {
        db.collection('orders')
          .where('hotel_id', '==', id)
          .where('date', '>=', yesterday)
          .get()
          .then(snapshots => {
            let data = [];
            snapshots.forEach(doc => data.push(doc.data()));
            resolve(data);
          })
          .catch(err => {
            !isCancel && setError([...error, err]);
          });
      });
    };
    if (hotelIds) {
      const promises = hotelIds.map(id => loadData(id));
      asyncAll(promises).then(result => {
        // 시간순으로 정렬
        const flatedArr = result.reduce((acc, val) => acc.concat(val), []);
        !isCancel && setOrderData(sortBy(flatedArr, 'date').reverse());
      });
    }
    return () => (isCancel = true);
  }, [db, error, user]);

  return (
    <>
      {orderData ? (
        <>
          <div
            style={{
              background: '#e6e6e6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              fontSize: '14px',
              color: '#55607d',
              position: 'sticky',
              top: '47px'
            }}
          >
            <div>전일 0시 부터 현재까지의 주문</div>
          </div>
          {orderData.length === 0 && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                marginTop: '1rem'
              }}
            >
              주문 정보가 없습니다.
            </div>
          )}
          {orderData.map((item, index) => (
            <OrderItem data={item} key={index} />
          ))}
          <div style={{ height: '47px' }}></div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default HotelOrders;
