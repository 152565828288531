import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import {
  formatPrice,
  dateToWords,
  statusToString,
  getEachTotal,
  getAllTotal,
  statusToColor
} from '../../common/format';
import ConfirmModal from './ConfirmModal';
import DenyModal from './DenyModal';
import addressIcon from '../../images/address.svg';
import { useSelector } from 'react-redux';
import { onSubmitByVroong, trackVroong } from '../../common/request';
import Loader from '../../components/Loader';

const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-size: 14px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  .order-bold {
    font-size: 12px;
    color: #aaaaaa;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    min-height: 48px;
    width: 90%;
    margin: 0 5%;
    &:last-child {
      border-bottom: none;
    }
  }
  .between {
    justify-content: space-between;
  }
  .border-top {
    padding: 0.5rem 0;
    border-top: 1px solid #e6e6e6;
  }
  .container-button {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 24px;
    width: 90%;
    margin: 1rem 5%;
    cursor: pointer;
  }
`;
const Table = styled.table`
  margin: 0 5%;
  width: 90%;
  border-collapse: collapse;
  th {
    border-bottom: 1px solid #e6e6e6;
    padding: 0.25rem 0;
  }
  td {
    text-align: center;
    padding: 0.25rem 0;
  }
`;
const Status = styled.div`
  padding: 0.8rem 0;
  text-align: center;
  background: ${props => props.background};
  width: 100%;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;
`;

const AcceptDetail = props => {
  const { orderNumber } = props.match.params;
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [select, setSelect] = useState(null);
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    db.collection('orders')
      .doc(orderNumber)
      .onSnapshot(doc => {
        if (!data) return;
        if (data.status !== doc.data().status) setData(doc.data());
      });
  }, [db, orderNumber, data]);

  useEffect(() => {
    db.collection('orders')
      .doc(orderNumber)
      .get()
      .then(doc => {
        const check = user && user.group && user.group.includes(doc.data().store_id);
        if (!check && user.authLevel !== 'ROOT') {
          return alert('비정상적인경로로 접속하였습니다.');
        }
        if (doc.data().vroong_data && doc.data().vroong_data.delivery_id) {
          // 부릉 주문이면 상태 체크
          trackVroong(doc.data().vroong_data.delivery_id, doc.data().status, orderNumber).then(result => {
            // console.log(result);

            db.collection('hotels')
              .doc(doc.data().hotel_id)
              .get()
              .then(hotel => {
                setData({
                  ...doc.data(),
                  hotelData: hotel.data()
                });
              })
              .catch(err => console.log(err));
          });
        } else {
          db.collection('hotels')
            .doc(doc.data().hotel_id)
            .get()
            .then(hotel => {
              setData({
                ...doc.data(),
                hotelData: hotel.data()
              });
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));
  }, [db, user]); // eslint-disable-line

  const checkStatus = current => {
    return db
      .collection('orders')
      .doc(orderNumber)
      .get()
      .then(doc => {
        return doc.data().status === current;
      });
  };

  // 주문 취소
  const denyOrder = async reason => {
    const check = await checkStatus(data.status);
    if (!check) return alert('오류: 중복 상태 변경요청 (새로고침 후 다시 시도해보세요.)');

    const saveCancelData = () => {
      return db
        .collection('orders')
        .doc(orderNumber)
        .update({
          status: -1,
          reason,
          lastStatusChanged: new Date()
        })
        .then(() => props.history.push('/accept'))
        .catch(err => console.log(err));
    };
    saveCancelData();
  };

  const completeOrder = async () => {
    const check = await checkStatus(data.status);
    if (!check) return alert('오류: 중복 상태 변경요청 (새로고침 후 다시 시도해보세요.)');

    db.collection('orders')
      .doc(orderNumber)
      .update({
        status: 4,
        lastStatusChanged: new Date()
      })
      .then(() => {
        props.history.push('/accept');
      })
      .catch(err => console.log(err));
  };

  // 직접배달 클릭시
  const onSubmitOrder = async () => {
    // alert('직접 배달은 주문상태가 바로 배달중으로 변경됩니다.');
    const check = await checkStatus(data.status);
    if (!check) return alert('오류: 중복 상태 변경요청 (새로고침 후 다시 시도해보세요.)');
    db.collection('orders')
      .doc(orderNumber)
      .update({
        status: 3,
        lastStatusChanged: new Date(),
        deliveryMethod: '직접배달'
      })
      .then(() => {
        props.history.push('/accept');
      })
      .catch(err => console.log(err));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {data ? (
        <Container>
          <div className="flex-row between" style={{ height: '32px' }}>
            <div className="order-bold">#{data.orderNumber}</div>
            <div style={{ fontSize: '12px', color: '#aaa' }}>{dateToWords(data.date.toDate())}</div>
          </div>
          <div className="flex-row">
            <img src={addressIcon} alt="" />
            {data.address}
          </div>
          {data.status >= 2 && data.vroong_data && (
            <>
              <div className="flex-row">
                <b>부릉 배달상태</b> &nbsp; 신청완료 ({data.vroong_data.delivery_id})
              </div>
              <div className="flex-row">
                <b>부릉 배달비</b> &nbsp;
                {data.vroong_data.sum_total && formatPrice(data.vroong_data.sum_total)}원
              </div>
            </>
          )}

          <Table>
            <thead>
              <tr>
                <th style={{ width: '55%' }}>메뉴</th>
                <th style={{ width: '15%' }}>수량</th>
                <th style={{ width: '30%' }}>금액</th>
              </tr>
            </thead>
            <tbody>
              {data.cart.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>{item.name}</td>
                    <td>{item.counter}</td>
                    <td style={{ textAlign: 'right' }}>{formatPrice(getEachTotal(item))}원</td>
                  </tr>
                  {item.options &&
                    item.options.length > 0 &&
                    item.options.map((option, index) => (
                      <tr
                        key={index}
                        style={{
                          borderLeft: '1px solid #e6e6e6',
                          color: '#aaa',
                          textAlign: 'left',
                          fontSize: '13px'
                        }}
                      >
                        <td style={{ textAlign: 'left' }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {option.realName} (+{formatPrice(option.price)})
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <div
            className="flex-row"
            style={{
              borderTop: '1px solid #e6e6e6',
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '0 5%',
              marginTop: '0.5rem',
              width: '90%'
            }}
          >
            <div style={{ whiteSpace: 'nowrap', marginRight: '0.5rem' }}>요청사항</div>
            <div style={{ fontSize: '12px', fontWeight: 400 }}>{data.defaultMessage}</div>
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '0 5%',
              width: '90%'
            }}
          >
            <div>배달비</div>
            <div style={{ fontSize: '14px', fontWeight: 400 }}>{data && formatPrice(data.deliveryFee)}원</div>
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '0 5%',
              width: '90%'
            }}
          >
            <div>총 금액</div>
            <div>{data && formatPrice(getAllTotal(data.cart, data.deliveryFee))}원</div>
          </div>

          {data.reason && (
            <div
              className="flex-row"
              style={{
                justifyContent: 'space-between',
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '0 5%',
                width: '90%'
              }}
            >
              <div>취소사유</div>
              <div style={{ fontSize: '14px', fontWeight: 400 }}>
                {data.reason === 'timeout' ? '자동취소 (5분 초과)' : '업체취소'}
              </div>
            </div>
          )}

          {data.status === 3 && (
            <div
              className="container-button"
              style={{
                background: 'green',
                color: 'white'
              }}
              onClick={() => {
                completeOrder();
              }}
            >
              주문완료하기
            </div>
          )}
          {data.status === 1 ? (
            <>
              <div
                className="container-button"
                style={{
                  background: '#ff3250',
                  color: 'white',
                  fontSize: '18px'
                }}
                onClick={() => {
                  // setSelect('CONFIRMED');
                  onSubmitOrder();
                }}
              >
                주문접수
              </div>

              <div
                style={{
                  marginTop: '0.5rem',
                  fontSize: '14px',
                  color: '#aaaaaa',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '3rem'
                }}
                onClick={() => {
                  setSelect('DENIED');
                  // denyOrder();
                }}
              >
                주문거절
              </div>
            </>
          ) : (
            <Status
              background={statusToColor(data.status).background}
              color={statusToColor(data.status).color}
              style={{ marginBottom: '2rem' }}
            >
              {statusToString(data.status)}
            </Status>
          )}
        </Container>
      ) : (
        <Loader />
      )}
      <>
        {select === 'CONFIRMED' && (
          <ConfirmModal
            submit={onSubmitOrder}
            submitVroong={pickuptime => {
              onSubmitByVroong(data, user, pickuptime).then(result => {
                // console.log(result);
                if (result.result === 'SUCCESS') {
                  const { delivery_id, sum_total, distance } = result;

                  db.collection('orders')
                    .doc(orderNumber)
                    .update({
                      status: 2,
                      vroong_data: { delivery_id, sum_total, distance },
                      lastStatusChanged: new Date()
                    })
                    .then(() => {
                      alert(`부릉 배송요청이 정상 처리 되었습니다. \n 배달료: ${sum_total}원, 거리: ${distance}m`);
                      props.history.push('/accept');
                    })
                    .catch(err => console.log(err));
                } else {
                  // 에러시 에러 로그 주문 디비에 저장
                  db.collection('orders')
                    .doc(orderNumber)
                    .update({
                      vroong_data: result
                    })
                    .then(() => {
                      alert(result.error_message);
                      setSelect(null);
                    })
                    .catch(err => console.log(err));
                }
              });
            }}
            cancel={() => {
              setSelect(null);
            }}
          />
        )}
        {select === 'DENIED' && (
          <DenyModal
            submit={denyOrder}
            cancel={() => {
              setSelect(null);
            }}
          />
        )}
      </>
    </div>
  );
};

export default AcceptDetail;
