import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from '../../components/ToggleButton';
import { setUser } from '../../redux_modules/user';
import { FirebaseContext } from '../../firebase';
import { success } from '../../components/Toast';
import { formatPhone } from '../../common/format';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  .item {
    display: flex;
    height: 48px;
    align-items: center;
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid #e6e6e6;
    b {
      margin-right: 0.25rem;
    }
  }
  .item-col {
    font-size: 14px;
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid #e6e6e6;
    padding: 0.25rem;
    b {
      margin-right: 0.25rem;
    }
  }
  .msg-input {
    border: none;
    outline: none;
    min-width: 100px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .title {
    background: #f9f9f9;
    font-weight: bold;
    display: flex;
    height: 48px;
    align-items: center;
    width: 90%;
    padding: 0 5%;
  }
  .link-btn {
    color: #fff;
    background: #aaa;
    border-radius: 4px;
    cursor: pointer;
    padding: 0.3rem 0.5rem;
    font-size: 13px;
    margin-left: 1rem;
    font-weight: bold;
    user-select: none;
    &:hover {
      opacity: 0.9;
    }
  }
`;

const EditMy = () => {
  const { user } = useSelector(state => state.user);
  const { db } = useContext(FirebaseContext);
  const [data, setData] = useState({ ...user });
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const saveData = num => {
    const phoneRule = /^\d{3}\d{3,4}\d{4}$/;
    if (!phoneRule.test(num)) {
      alert('핸드폰번호가형식에 맞지 않습니다.');
      setData({ ...data, phone2: '' });
    } else {
      dispatch(setUser(data));
      db.collection('users')
        .doc(data.uid)
        .update({
          phone2: num
        })
        .then(_ => success('추가전송번호가 변경되었습니다.'));
    }
  };

  const saveActive = current => {
    db.collection('users')
      .doc(data.uid)
      .update({
        phone2Active: current
      })
      .then(_ => success(current ? '추가전송번호가 사용 설정되었습니다.' : '추가전송번호가 일시정지 되었습니다.'));
  };

  return (
    <Container>
      {data && (
        <>
          <div className="title">내 정보</div>
          <div className="item">
            <b>사업자명</b> {data.businessName}
          </div>
          {data.businessType === '배달' && (
            <div className="item-col">
              <div style={{ marginTop: '0.5rem' }}>
                <b style={{ fontSize: '16px' }}>주문 알림톡 설정</b>
              </div>
              <div style={{ marginTop: '0.5rem' }}>
                <b style={{ color: '#aaa' }}>
                  {' '}
                  <span style={{ color: '#ff3250' }}>*</span>기본전송번호
                </b>
                {formatPhone(data.phone)}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <b style={{ color: '#aaa' }}> + 추가전송번호</b>
                  {isEdit ? (
                    <input
                      defaultValue={data.phone2}
                      className="msg-input"
                      placeholder="-없이"
                      onChange={e => {
                        setData({ ...data, phone2: e.target.value });
                      }}
                    />
                  ) : (
                    <span
                      style={{
                        marginLeft: '6px'
                      }}
                    >
                      {formatPhone(data.phone2)}
                    </span>
                  )}
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: '#ff3250',
                      cursor: 'pointer',
                      marginLeft: '6px'
                    }}
                    onClick={_ => {
                      setIsEdit(!isEdit);
                      isEdit && saveData(data.phone2);
                    }}
                  >
                    {isEdit ? '완료' : data.phone2 ? '수정' : '추가'}
                  </span>
                </div>
                <ToggleButton
                  style={{ marginRight: '6px' }}
                  defaultChecked={data.phone2Active}
                  onChange={e => {
                    saveActive(e.target.checked);
                  }}
                />
              </div>
            </div>
          )}
          <div className="item">
            <b>이름</b> {data.name}
          </div>
          <div className="item">
            <b>사업자등록번호</b>
            {data.bn && data.bn.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}
          </div>
          <div className="item">
            <b>생년월일</b>
            {data.birth}
          </div>
          <div className="item">
            <b>은행</b>
            {data.bankName}
          </div>
          <div className="item">
            <b>계좌번호</b>
            {data.account}
          </div>
          <div className="item">
            <b>사업자등록증</b>
            <a href={data[`file-1`]} download target="_blank" rel="noopener noreferrer" className="link-btn">
              파일보기
            </a>
          </div>
          <div className="item">
            <b>통장사본</b>
            <a href={data[`file-2`]} download target="_blank" rel="noopener noreferrer" className="link-btn">
              파일보기
            </a>
          </div>
          {data.businessType === '배달' && (
            <div className="item" style={{ padding: '1rem 0' }}>
              <div style={{ fontSize: '12px', color: '#aaa' }}>
                * 기본전송번호 변경은 고객센터로 연락해주세요. <br />
                우측의 토글 버튼으로 추가전송번호의 전송여부를 설정할 수 있습니다.
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default EditMy;
