import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import OrderList from './pages/Order/OrderList';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Info from './pages/Info/Info';
import Login from './pages/Login';
import Order from './pages/Order/Order';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseContext } from './firebase';
import EditMy from './pages/Info/EditMy';
import EditStoreInfo from './pages/Info/EditStoreInfo';
import { setUser } from './redux_modules/user';
import Join from './pages/Join';
import PrivateRoute from './components/PrivateRoute';
import AcceptList from './pages/ForStore/AcceptList';
import AcceptDetail from './pages/ForStore/AcceptDetail';
import CompletedOrder from './pages/Order/Calc';
import Terms from './Terms';
import Terms2 from './Terms2';
import InfoForHotel from './pages/ForHotel/InfoForHotel';
import EditHotelInfo from './pages/ForHotel/EditHotelInfo';
import HotelOrders from './pages/ForHotel/HotelOrders';
import HotelOrderDetail from './pages/ForHotel/HotelOrderDetail';
import HotelCalcList from './pages/ForHotel/HotelCalcList';

const AppContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 768px;
  }
`;

const App = props => {
  const path = props.location.pathname;
  const { auth, db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const login = user => {
    dispatch(setUser(user));
  };

  const logout = () => {
    dispatch(setUser(null));
  };

  useEffect(() => {
    auth.onAuthStateChanged(result => {
      if (result) {
        if (!user) {
          const users = db.collection('users');
          users
            .doc(result.uid)
            .get()
            .then(doc => {
              login({ ...doc.data(), uid: result.uid });
              // console.log('redirect 실행');
              const step = (doc.data() && doc.data().step) || null;
              if (!step) {
                alert('접속 권한이 없습니다.');
                logout();
                auth.signOut();
                return null;
              }
              if (step === 1) {
                props.history.push('/join/2');
              } else {
                (path === '/login' || path === '/') && props.history.push('/accept');
              }
            });
        } else {
          // console.log("유저정보있음");
          if (user.step === 1) {
            props.history.push('/join/2');
          } else {
            (path === '/login' || path === '/') && props.history.push('/accept');
          }
        }
      } else {
        // console.log('로그아웃상태');
        logout();
      }
    });
  }, [auth, path]); // eslint-disable-line

  return (
    <>
      <Header />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '2rem',
          minHeight: '78vh'
        }}
      >
        <Switch>
          <AppContainer>
            <Route exact path="/login" component={Login} />
            <Route exact path="/join/:step" component={Join} />
            <Route exact path="/terms/1" component={Terms} />
            <Route exact path="/terms/2" component={Terms2} />
            <PrivateRoute exact path="/" component={Info} />
            <PrivateRoute exact path="/info" component={Info} />
            <PrivateRoute exact path="/info/edit" component={EditMy} />
            <PrivateRoute exact path="/info/hotel" component={InfoForHotel} />
            <PrivateRoute exact path="/info/store/:id" component={EditStoreInfo} />
            <PrivateRoute exact path="/info/hotel/:id" component={EditHotelInfo} />
            <PrivateRoute exact path="/accept/hotel" component={HotelOrders} />
            <PrivateRoute exact path="/accept" component={AcceptList} />
            <PrivateRoute exact path="/accept/detail/:orderNumber" component={AcceptDetail} />
            <PrivateRoute exact path="/accept/hotel/:orderNumber" component={HotelOrderDetail} />
            <PrivateRoute exact path="/calc" component={CompletedOrder} />
            <PrivateRoute exact path="/calc/hotel" component={HotelCalcList} />
            <PrivateRoute exact path="/order" component={Order} />
            <PrivateRoute exact path="/order/:id" component={OrderList} />
            <Redirect to="/" component={Info} />
          </AppContainer>
        </Switch>
      </div>
      {path === '/login' && <Footer />}
    </>
  );
};

export default withRouter(App);
