import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import { withRouter } from 'react-router-dom';
import { success } from '../../components/Toast';
import ToggleButton from '../../components/ToggleButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .item {
    display: flex;
    min-height: 48px;
    align-items: center;
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid #e6e6e6;
    white-space: nowrap;
  }
  .title {
    background: #f9f9f9;
    font-weight: bold;
    display: flex;
    height: 48px;
    align-items: center;
    width: 90%;
    padding: 0 5%;
  }
  .msg-input {
    border: none;
    outline: none;
    min-width: 160px;
    width: 70%;
    min-height: 60px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    padding: 0.2rem;
  }
`;

const EditHotelInfo = props => {
  const { db } = useContext(FirebaseContext);
  const { id } = props.match.params;
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    db.collection('hotels')
      .doc(id)
      .get()
      .then(doc => setData(doc.data()))
      .catch(err => console.log(err));
  }, [db, id]);

  const saveData = defaultMessage => {
    return db
      .collection('hotels')
      .doc(id)
      .update({ defaultMessage })
      .then(_ => success('변경되었습니다.'))
      .catch(err => console.log(err));
  };

  const changeStatus = active => {
    return db
      .collection('hotels')
      .doc(id)
      .update({ active })
      .then(() => {
        if (!active) {
          success(
            <>
              비활성화 되었습니다. <br />
              (다음 영업일 오픈시간에 자동 전환 됩니다.)
            </>
          );
        } else {
          success('활성화 되었습니다.');
        }
      });
  };

  return (
    <>
      <Container>
        {data && (
          <>
            <div className="title">{data.name}</div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>전화번호</b>
              {data.phone}
            </div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>주소</b>
              <div>
                <div>{data.address.jibun}</div>
                <div style={{ color: '#aaa', fontSize: '12px' }}>{data.address.road}</div>
              </div>
            </div>

            <div className="item">
              <b style={{ marginRight: '6px' }}>업종분류</b>
              {data.type}
            </div>
            <div className="item">
              <b style={{ marginRight: '6px' }}>기본주문요청</b>
              {!isEdit && (
                <div style={{ fontSize: '14px', whiteSpace: 'normal', padding: '0.5rem 0.2rem' }}>
                  {data.defaultMessage}
                </div>
              )}
              {isEdit && (
                <textarea
                  autoFocus
                  className="msg-input"
                  defaultValue={data.defaultMessage}
                  placeholder="주문, 배달 시 요청사항을 적어주세요. 최대(100자)"
                  onChange={e => {
                    if (e.target.value.length > 100) {
                      return alert('주문요청사항은 100자를 초과할 수 없습니다.');
                    } else {
                      setData({ ...data, defaultMessage: e.target.value });
                    }
                  }}
                />
              )}
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: isEdit ? '#aaa' : '#ff3250',
                  cursor: 'pointer',
                  marginLeft: '6px'
                }}
                onClick={_ => {
                  setIsEdit(!isEdit);
                  isEdit && saveData(data.defaultMessage);
                }}
              >
                {isEdit ? '저장' : '수정'}
              </span>
            </div>
            <div className="item" style={{ justifyContent: 'space-between' }}>
              <b>배달가능상태</b>
              <ToggleButton
                style={{ marginRight: '6px' }}
                defaultChecked={data.active}
                onChange={e => {
                  changeStatus(e.target.checked);
                }}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default withRouter(EditHotelInfo);
