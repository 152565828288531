import React from "react";
import styled from "styled-components";

const Container = styled.div`
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background: ${props => (props.active ? "#ff3250" : "gray")};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  width: ${props => props.width};
  text-align: center;
  margin-top: 0.5rem;
  min-width: 200px;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`;

const Button = ({ children, onClick, width, style, active }) => {
  return (
    <Container
      onClick={active && onClick}
      width={width}
      style={{ ...style }}
      active={active}
    >
      {children}
    </Container>
  );
};

export default Button;
