import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../common/hooks';
import Loader from '../../components/Loader';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 80%;
  background: white;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  min-height: 200px;
`;

const ButtonContainer = styled.div`
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background: ${props => props.color};
  width: 80%;
  cursor: pointer;
  text-align: center;
  margin-top: 0.5rem;
`;

const ConfirmModal = ({ submit, cancel, submitVroong }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => cancel());
  const [step, setStep] = useState(1);

  return (
    <Container>
      <Modal ref={ref}>
        {step === 1 && (
          <>
            <br />
            <h3>배달 방법을 선택해주세요.</h3>
            <br />

            <ButtonContainer
              onClick={() => {
                setStep(2);
              }}
              color="#00b598"
            >
              부릉으로 배달
            </ButtonContainer>

            <ButtonContainer
              onClick={() => {
                submit();
              }}
              color="#74c0fc"
            >
              자체적으로 배달
            </ButtonContainer>
          </>
        )}
        {step === 2 && (
          <>
            <br />
            <h3>픽업 요청 시간을 선택해주세요.</h3>
            <br />
            <ButtonContainer
              onClick={() => {
                setStep(3);
                submitVroong(900);
              }}
              color="gray"
            >
              15분 후
            </ButtonContainer>
            <ButtonContainer
              onClick={() => {
                setStep(3);
                submitVroong(1800);
              }}
              color="gray"
            >
              30분 후
            </ButtonContainer>
            <ButtonContainer
              onClick={() => {
                setStep(3);
                submitVroong(2700);
              }}
              color="gray"
            >
              45분 후
            </ButtonContainer>
            <ButtonContainer
              onClick={() => {
                setStep(3);
                submitVroong(3600);
              }}
              color="gray"
            >
              60분 후
            </ButtonContainer>
          </>
        )}
        {step === 3 && <Loader />}
      </Modal>
    </Container>
  );
};

export default ConfirmModal;
