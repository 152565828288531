import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../images/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import { MdNotifications, MdPerson, MdDateRange } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  height: 47px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  user-select: none;
  .logo {
    width: 96px;
    height: 24px;
  }
`;

const Bar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-top: solid 1px #e6e6e6;
  height: 64px;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 200;
  user-select: none;
  overflow-x: scroll;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`;

const Item = styled.span`
  display: flex;
  padding: 0 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => (props.active ? '#ff3250' : '#55607d')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-size: 13px;
  height: ${props => (props.active ? '61px' : '64px')};
  border-top: ${props => props.active && '3px solid #ff3250'};
  transition: 0.1s all ease-out;
  white-space: nowrap;
`;

const Header = ({ location }) => {
  const path = location.pathname.split('/')[1];
  const [tab, setTab] = useState(path);

  useEffect(() => {
    setTab(path);
  }, [path]);

  return (
    <>
      <Container>
        <div style={{ width: 73, marginTop: '6px' }}></div>

        {path === 'login' || path === 'join' || path === 'terms' ? (
          <Link
            to="/info"
            style={{
              color: '#000',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img src={logo} className="logo" alt="" id="header-logo" />
          </Link>
        ) : (
          <div>델리퀵 파트너</div>
        )}
        <span style={{ minWidth: 73, height: 1 }}></span>
      </Container>
      {path !== 'login' && path !== 'join' && path !== 'terms' && (
        <Bar>
          <Link to={`/accept`} style={{ width: '33.3%' }}>
            <Item active={tab === 'accept'}>
              <MdNotifications size="28px" />
              <span>주문</span>
            </Item>
          </Link>
          <Link to={`/calc`} style={{ width: '33.3%' }}>
            <Item active={tab === 'calc'}>
              <MdDateRange size="28px" />
              <span>정산</span>
            </Item>
          </Link>
          <Link to={`/info`} style={{ width: '33.3%' }}>
            <Item active={tab === 'info'}>
              <MdPerson size="28px" />
              <span>내 정보</span>
            </Item>
          </Link>

          {/* <Link to={`/notice`}>
            <Item active={tab === 'calc'}>공지사항</Item>
          </Link> */}
        </Bar>
      )}
    </>
  );
};

export default withRouter(Header);
