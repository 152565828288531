import React from 'react';
import './toggle.css';

const ToggleButton = ({ onChange, style, defaultChecked }) => {
  return (
    <div style={style} className="toggle">
      <input type="checkbox" onChange={onChange} defaultChecked={defaultChecked} />
    </div>
  );
};

export default ToggleButton;
