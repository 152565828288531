import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 80%;
  background: white;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  width: 80%;
  cursor: pointer;
  text-align: center;
  margin-top: 0.5rem;
`;

const DenyModal = ({ submit, cancel }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => cancel());

  return (
    <Container>
      <Modal ref={ref}>
        <p>
          <h3>취소 사유를 선택해주세요.</h3>
        </p>

        <ButtonContainer
          onClick={() => {
            submit("outofstock");
          }}
        >
          재료소진
        </ButtonContainer>

        <ButtonContainer
          onClick={() => {
            submit("cannot");
          }}
        >
          배달불가
        </ButtonContainer>

        <ButtonContainer
          onClick={() => {
            submit("etc");
          }}
        >
          업체사정
        </ButtonContainer>
      </Modal>
    </Container>
  );
};

export default DenyModal;

// Hook
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
